import React        from 'react';
import Form         from "@uBehaviour/form"; 
import Input        from '@cComponents/input';
import Field        from '@cComponents/field';
import T            from '@uBehaviour/i18n';
import Application  from '@uBehaviour/application';
import Button       from "@cComponents/button";

import './form.css';

class FilterForm extends React.Component {

  constructor(props) {
    super(props);
    this._form        = React.createRef();
    this._presubmit   = this._presubmit.bind(this);
    this._submit      = this._submit.bind(this);
    this.state        = {
      deletionAsked: false
    }
  }

  _presubmit(form, filter) {
    filter.tenant = this.props.currentTenant.currentId;
    filter.values = this.props.aggregator.deshydrate();
    filter.type = this.props.type;
    return filter;
  }

  _submit(form, filter) {
    const isNew = !filter._id;
    const method = !isNew 
      ? this.props.api.service("filters", "put").execute(filter._id, filter)
      : this.props.api.service("filters", "post").execute(filter)
    method.then((filter) => {
      if (isNew && this.props.onCreated) {
        this.props.onCreated(filter)
      } else if (!isNew && this.props.onUpdated) {
        this.props.onUpdated(filter);
      }
      this.props.close()
    });
  }

  _delete(filterId) {
    this.props.api.service("filters", "delete").execute(filterId)
    .then(() => {
      this.props.close();
      if (this.props.onDeleted) {
        this.props.onDeleted(filterId);
      }
    });      
  }

  render() {
    return (
      <Form.Simple
        ref={ this._form }
        default={ this.props.default }
        preload={ this._preload }
        presubmit={ this._presubmit }
        submit={ this._submit }
      >
        {(ctx, filter, errors, form) => (
          <>
            <div className="bs-filterForm-header"><T bind={{ name: filter.name  }}>filter_form_header</T></div>
            <div className="bs-filterForm-content">
              <Field.ShortLabelContext prefix="filter_form">
                <div>
                  <h4><T>filter_form_fieldGroup_global_infos</T></h4>
                  <Field.Short name="name" required><Input.Text /></Field.Short>
                  <Field.Short name="default">
                    <Input.Radio.Btn inline>
                      <Input.Radio.Value value={true}><T>yes</T></Input.Radio.Value>
                      <Input.Radio.Value value={false}><T>no</T></Input.Radio.Value>
                    </Input.Radio.Btn>
                  </Field.Short>
                </div>
                <div>
                  <h4><T>filter_form_fieldGroup_selected_filters</T></h4>
                  <div className="bs-filterForm-filters-containers" >{this.props.aggregator.values.map(v => (<div className="bs-filterForm-filter-item">{v.stringify()}</div>))}</div>                    
                </div>
              </Field.ShortLabelContext>          
            </div>
            <div className="bs-filterForm-footer">
              {this.state.deletionAsked 
                ? <Button.Text className="bs-form-remove-btn" onClick={ () => this._delete(filter._id)}><T>filter_form_remove_confirmation</T></Button.Text>
                : <Button.Text onClick={ () => this.setState({deletionAsked: true})}><T>filter_form_remove</T></Button.Text>
              }
              <Button.Text onClick={ () => form.submit(true) }><T>filter_form_submit</T></Button.Text>
            </div>
          </>
        )}
      </Form.Simple>
    )
  }
}

export default Application.Service.forward(["api", "currentTenant"], FilterForm)

