import React, { useRef }  from 'react';
import _                  from "lodash";
import moment             from "moment"
import Hiddenable         from '@cComponents/hiddenable';
import T                  from '@uBehaviour/i18n';
import Data               from '@uBehaviour/data';
import Item               from '@entities/assignments/item';
import Application        from "@uBehaviour/application";
import FilteredList       from "@cFeatures/filter/list";
import Filters            from '@entities/assignments/filters';
import IssueSearch        from '@entities/assignmentsAndIssues/search';
import Query              from '@uLib/query';

import './lists.css';
import { IssueDetail } from '../issues';
import { Link, Route } from '@common/features/router';

export default Application.Service.forward(["session", "currentTenant"], ({ session, currentTenant }) => {
  const user = session.user;
  const _defaultQuery = { 
    $or: [
      { "assignment.agents": user._id }
    ]
  };
  if(user.team){
    _defaultQuery.$or.push({ "assignment.team": user.team });
  }

  const _buildFilteredList = ({ query, title, sort }) => {
    const hiddenableRef  = useRef(null);
    const listRef        = useRef(null);
    const load = { 
      'files': 1,
      'createdBy': 1,
      'updatedBy': 1,
      'manager': {
        'avatar': 1
      },
      'category': 1,
      'location.building': 1,
      'assignment.team': 1,
      'assignment.agents': 1
    };
    return (
      <FilteredList.List
        getHeight={() => hiddenableRef.current.height}
        load={() => listRef.current.loadNext()}
        isLoadable={() => hiddenableRef.current.isOpen() && !listRef.current.isFinishedLoading()}
      >
      {composeQuery => {
        const composedQuery = composeQuery({
          ..._defaultQuery, 
          ...query
        });
        return (
          <Data.Count 
            model="Assignment" 
            query={Query.joinWithOptimizer(composedQuery, { tenant: currentTenant.currentId })}
          >
          {(nbr) => (
            <Hiddenable ref={hiddenableRef}>
              <Hiddenable.Title><span>{title}&nbsp;<small>({nbr})</small></span></Hiddenable.Title>
              <Hiddenable.Content>
                  <Data.List 
                    ref={listRef}
                    model="Assignment"
                    query={Query.joinWithOptimizer(composedQuery, { tenant: currentTenant.currentId })}
                    sort={sort}
                    pageSize={20}
                    load={load}
                  >
                  {(data) => (
                    <Link to={`/${data.issue }`}>
                      <Item data={ data } />
                    </Link>
                  )}
                  </Data.List>
              </Hiddenable.Content>
            </Hiddenable>
          )}
        </Data.Count>  
        )
      }}
      </FilteredList.List>
    )    
  }
  
  return (
    <>
      <FilteredList type="assignments" myFilterTitle={<T>assignments_list_myFilter</T>}>
        <FilteredList.Filter>
          <Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <div className="bs-assignments-lists-header">
            <IssueSearch assignmentsPath="assignment" issueIdPath="issue"/>
          </div>
        </FilteredList.Header>
        {/* En retard / Non terminés */}
        {_buildFilteredList({
          query: {
            "assignment.progress": {$ne: "finished"},
            "assignment.scheduledTo": { $lte: moment().startOf("day").toISOString() }
          }, 
          title: <T>assignments_list_section_label_outdated</T>,
          sort: { priority: -1, "assignment.scheduledTo": 1 }
        })}
        {/* Non Planifiées */}
        {_buildFilteredList({
          query: {
            "assignment.progress": {$ne: "finished"},
            "assignment.scheduledFrom": null
          }, 
          title: <T>assignments_list_section_label_unplanned</T>,
          sort: { priority: -1, createdAt: 1 }
        })}
        {/* Aujourd'hui à nombre de jour de planning visible */}
        {_.range(0, session.user.settings.nbrDisplayedDays || 7).map(day => {
          const title = day === 0
          ? <T>assignments_list_section_label_today</T>
          : day === 1 
            ? <T>assignments_list_section_label_tomorrow</T>
            : _.upperFirst(moment().add(day, 'day').format('dddd'));


          const start = moment().startOf("day").add(day, "day");
          const end   = start.clone().add(1, "day");
          return _buildFilteredList({
            query: {
              "assignment.progress": { $ne: "finished" },
              "assignment.scheduledFrom": { $lt: end.toISOString() },
              "assignment.scheduledTo": { $gt: start.toISOString() }
            }, 
            title: title,
            sort: { priority: -1 }
          });
        })}
        {/* A venir */}
        {user.settings.nbrDisplayedDays === 0 && _buildFilteredList({
          query: {
            "assignment.progress": {$ne: "finished"},
            "assignment.scheduledFrom": { $gt: moment().add(7, "days").toISOString()}
          }, 
          title: <T>assignments_list_section_label_future</T>,
          sort: { "assignment.scheduledFrom": 1 }
        })}
        {/* Terminées */}
        {_buildFilteredList({
          query: { "assignment.progress": "finished" }, 
          title: <T>assignment_finish</T>,
          sort: { "assignment.scheduledTo": -1 }
        })}
      </FilteredList>
      <Route path="/:id" component={ IssueDetail } />
    </>
  );
});