import React          from 'react';
import _              from 'lodash';
import FilterForm     from "./form.jsx";
import Collapsable    from '@cComponents/collapsable';
import CFilter        from '@cComponents/filter';
import Display        from '@uComponents/displayIf';
import Modal          from "@cComponents/modal";
import Input          from '@cComponents/input';
import T              from '@uBehaviour/i18n';
import Application    from '@uBehaviour/application';

import "./manager.css";

class Manager extends React.Component {
  static contextType = CFilter.Context;
  constructor(props) {
    super(props);
    this.state = {
      displayForm: false,
      filterToModify: null,
      filters: [{
        name: this.props.defaultName ? this.props.defaultName : this.props.i18n.translate("filter_manager_default"),
        values: this.props.default ? this.props.default : [],
        _id: "see_all_filters"
      }]
    };
  }

  componentDidMount() {
    this.props.api.service("filters", "get").execute({ type: this.props.type, tenant: this.props.currentTenant.currentId }, { name: 1 }).then(filters => {
      if (filters.length) {
        this.context.aggregator.hydrate(filters.some(f => f.default) ? filters.find(f => f.default).values : []);
        this.setState((state) => ({ filters: state.filters.concat(filters) }));
      }      
    })
  }

  _onFilterChange = (filterId) => {
    this.context.aggregator.hydrate(this.state.filters.find(f => f._id === filterId).values);
  }

  _orderFilterValue = (filterValue) => {
    return filterValue.sort((f1, f2) => {
      const nameCompare = f1.name.localeCompare(f2.name);
      if(nameCompare !== 0){
        return nameCompare;
      }
      if(_.isString(f1.value) && _.isString(f2.value)){
        return f1.value.localeCompare(f2.value);
      }
      if(_.isBoolean(f1.value) && _.isBoolean(f2.value)){
        return f1.value ? -1 : 1;
      }
      if(_.isInteger(f1.value) && _.isInteger(f2.value)){
        return f1.value - f2.value;
      }
      return 0;
    });
  }

  getCurrentSelectedFilter = () => {
    const jsonAggregatorValue = JSON.stringify(this._orderFilterValue(this.context.aggregator.deshydrate()));
    return this.state.filters.find(f => JSON.stringify(this._orderFilterValue(f.values.slice())) === jsonAggregatorValue);
  }

  _onClickToEditFilter = (e, filter) => {
    e.stopPropagation();

    const equivalentFilter = this.getCurrentSelectedFilter();
    if(!equivalentFilter || equivalentFilter._id === filter._id){
      this.setState({ filterToModify: _.pick(filter, ["_id", "name", "default"]) });
    }else{
      this._popErrorEquivalentFilter(equivalentFilter, "update");
    }
  }

  _onClickToCreate = (e) => {
    e.stopPropagation();
    const equivalentFilter = this.getCurrentSelectedFilter();
    if(!equivalentFilter){
      this.setState({ displayForm: true });
    }else{
      this._popErrorEquivalentFilter(equivalentFilter, "create");
    }
  }

  _popErrorEquivalentFilter = (equivalentFilter, type) => {
    this.props.message.send("error", this.props.i18n.translateString(`filter_manager_errorEquivalentFilter_${type}`, {
      filter: equivalentFilter.name
    }, true));
  }

  _closeForm = () => {
    this.setState({ 
      displayForm: false,
      filterToModify: null
    });
  }

  _onFormCreate = (filter) => {
    this.setState((state) => ({ filters: [...state.filters, filter] }));
  }

  _onFormUpdate = (filter) => {
    this.setState((state) => {
      const filters = state.filters.slice();
      //Si le filtre actuel est paramétré en tant que filtre par défaut
      //MAJ de l'ancien filtre par défaut pour passer sa valeur à { default: false }
      if (filter.default && filters.some(f => f.default)) {
        const currentDefaultFilter = filters.find(f => f.default);
        currentDefaultFilter.default = false;
      }
      //MAJ du filtre 
      const index = filters.findIndex(f => f._id === filter._id);
      filters[index] = filter;
      return { filters };
    });
  }

  _onFormDelete = (filterId) => {
    this.setState((state) => ({ filters: state.filters.filter(f => f._id !== filterId) }));
  }

  _closeError = () => {
    this.setState({ errorEquivalentFilter: null });
  }

  render() {
    if(!this.context.aggregator.isReady()){
      return null;
    }
    const { displayForm, filterToModify, errorEquivalentFilter } = this.state;

    const selectedFilter = this.getCurrentSelectedFilter();
    const selectedFilterId = selectedFilter
      ? selectedFilter._id
      : null;

    const formFilter = filterToModify ? filterToModify : { default: false };

    const { title } = this.props;

    return (
      <>
        <Collapsable defaultOpen={true}>
          <Collapsable.Title>
              <div className="bs-filters-manager-title">
                <span>{ title }</span>
                <span onClick={ this._onClickToCreate } className="bs-filters-add-btn fa fa-plus-circle" />
              </div>
          </Collapsable.Title>
          <Collapsable.Content>
            <div className="bs-filters-manager-content">
              <Input.Radio.BtnFilter onChange={ this._onFilterChange } value={ selectedFilterId } >
                {this.state.filters.map((filter, idx) => (
                  <Input.Radio.Value key={ filter._id } value={ filter._id }>
                    <div className="bs-filters-manager-items-container">
                      <div>
                        { filter.name }
                        { filter.default && (
                          <span className="bs-filters-manager-defaultFilter fa fa-dot-circle-o" />
                        )}
                      </div>
                      {idx > 0 && (
                        <span onClick={e => this._onClickToEditFilter(e, filter)} className="fa fa-pencil" />
                      )}
                    </div>
                  </Input.Radio.Value>
                ))} 
              </Input.Radio.BtnFilter>
            </div>
          </Collapsable.Content>
        </Collapsable>

        <Display.If condition={ displayForm || filterToModify }>
          <Modal.Show style={{ width: "40vw" }} close={ this._closeForm }>
            <FilterForm 
              aggregator={ this.context.aggregator }
              type={ this.props.type }
              onCreated={ this._onFormCreate }
              onUpdated={ this._onFormUpdate }
              onDeleted={ this._onFormDelete }
              default={ formFilter }
            />
          </Modal.Show>
        </Display.If>
      </>
    );
  }
} 

export default Application.Service.forward(["api", "i18n", "message", "currentTenant"], Manager)
