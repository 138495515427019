import React         from "react";
import T             from "@cBehaviour/i18n";
import Filter        from "@cComponents/filter";
import Input         from "@cComponents/input";
import Collapsable   from "@cComponents/collapsable";

import { CountryList as countries }     from "@uTypes/business/Country";
import Acl from "@universal/behaviour/acl";

const getIsClientFilter = (values) => {
  const filter = {};
  if (values.length === 1) {
    filter["settings.commercialOffer"] = values.includes(true)
      ? { $ne: null }
      : null;
  }
  return filter;
}

const tenantFilter = (props) => (
  <>
    <Acl.If resource="application" action="administrate">
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>tenant_filter_commercialOffer</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="commercialOffer" buildQuery={values => ({ "settings.commercialOffer": { $in: values } })} stringify={commercialOffer => <T>{"tenant_filter_commercialOffer_" + commercialOffer}</T>}>
            {(values, add, drop, clear) => (
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
                <Input.Checkbox.Value value="starter"><T>tenant_filter_commercialOffer_starter</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value="standard"><T>tenant_filter_commercialOffer_standard</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value="expert"><T>tenant_filter_commercialOffer_expert</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            )}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
    </Acl.If>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T>tenant_filter_country</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="country" buildQuery={values => ({ "country": { $in: values } })} stringify={country => <T>{"tenant_country_" + country}</T>}>
          {(values, add, drop, clear) => (
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
            {
              ((props.user && (props.user.geographicalAreas && props.user.geographicalAreas.length ? props.user.geographicalAreas : null)) || countries).map(c => (<Input.Checkbox.Value key={c} value={c}><T>{"tenant_country_" + c}</T></Input.Checkbox.Value>))
            }
            </Input.Checkbox.BtnFilter>
          )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T>tenant_filter_type</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="type" buildQuery={values => ({ "discriminator": { $in: values } })} stringify={value => (<T>{value}</T>)}>
          {(values, add, drop, clear) => (
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
              <Input.Checkbox.Value value={"town"}><T>town</T></Input.Checkbox.Value>
              <Input.Checkbox.Value value={"entity"}><T>entity</T></Input.Checkbox.Value>
            </Input.Checkbox.BtnFilter>
          )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T>tenant_filter_citizen</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="citizenAllow" buildQuery={values => ({ "settings.citizen.allowed": { $in: values } })} stringify={value => (<T>{value ? "tenant_filter_citizen_str_allowed" : "tenant_filter_citizen_str_notAllowed"}</T>)}>
          {(values, add, drop, clear) => (
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
              <Input.Checkbox.Value value={true}><T>tenant_filter_citizen_allowed</T></Input.Checkbox.Value>
              <Input.Checkbox.Value value={false}><T>tenant_filter_citizen_notAllowed</T></Input.Checkbox.Value>
            </Input.Checkbox.BtnFilter>
          )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T>client</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="client" buildQuery={getIsClientFilter} stringify={value => (<T>{value ? "client" : "client_not"}</T>)}>
        {(values, add, drop, clear) => (
          <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
            <Input.Checkbox.Value value={true}><T>yes</T></Input.Checkbox.Value>
            <Input.Checkbox.Value value={false}><T>no</T></Input.Checkbox.Value>
          </Input.Checkbox.BtnFilter>
        )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
  </>
);

export default tenantFilter;