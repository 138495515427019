// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-filterForm-header {
  background-color: #f5f5f5;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
  color: #2c3e50;
  font-weight: 600;
}

.bs-filterForm-content {
  padding: 10px;
}

.bs-filterForm-footer {
  background-color: #f5f5f5;
  padding: 10px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
}

.bs-filterForm-content h4 {
  padding: 5px;
  border-bottom: 2px solid #cecbc8;
  font-size: 1.2em;
  color: #5a6266;
  font-weight: 600;
}

.bs-filterForm-content .bs-filterForm-filters-containers {
  display: flex;
  flex-wrap: wrap;
  
}
.bs-filterForm-content .bs-filterForm-filter-item {
  background-color: #e9e9e9;
  color: rgb(94, 103, 108);
  padding: 1px 5px;
  margin: 2px;
}

.bs-form-remove-btn {
  background-color: #c0392b;
}`, "",{"version":3,"sources":["webpack://./../common/features/filter/form.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,6BAA6B;EAC7B,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,0BAA0B;EAC1B,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;;AAEjB;AACA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".bs-filterForm-header {\n  background-color: #f5f5f5;\n  height: 43px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 1.2em;\n  padding: 0 10px;\n  border-bottom: 1px solid #ddd;\n  color: #2c3e50;\n  font-weight: 600;\n}\n\n.bs-filterForm-content {\n  padding: 10px;\n}\n\n.bs-filterForm-footer {\n  background-color: #f5f5f5;\n  padding: 10px;\n  border-top: 1px solid #ddd;\n  display: flex;\n  justify-content: space-between;\n}\n\n.bs-filterForm-content h4 {\n  padding: 5px;\n  border-bottom: 2px solid #cecbc8;\n  font-size: 1.2em;\n  color: #5a6266;\n  font-weight: 600;\n}\n\n.bs-filterForm-content .bs-filterForm-filters-containers {\n  display: flex;\n  flex-wrap: wrap;\n  \n}\n.bs-filterForm-content .bs-filterForm-filter-item {\n  background-color: #e9e9e9;\n  color: rgb(94, 103, 108);\n  padding: 1px 5px;\n  margin: 2px;\n}\n\n.bs-form-remove-btn {\n  background-color: #c0392b;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
