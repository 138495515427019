import React                                from 'react'
import { cloneDeep }                        from 'lodash'
import OverlayView                          from 'client/features/app/containers/OverlayView.jsx'
import _                                    from 'lodash'
import relativeTime                         from 'dayjs/plugin/relativeTime';
import dayjs                                from 'dayjs';
import T                                    from 'i18n-react'
import classNames                           from 'classnames'
import Dropzone                             from 'client/features/common/components/Dropzone'

import { getIssueFromAll }                  from 'client/features/issues/lib'
import {
  IssueRequest,
  displayNearIssue,
  closeIssue,
  setIssuesFiltersValue,
  setIssuesSearchValue,
  loadFilters
}                                           from 'client/features/issues/actions'
import * as ActionCalendar                  from 'client/features/calendar/actions'
import { setMentionsHastags, isAuthorized } from 'client/features/issues/lib'
import randomKey                            from 'random-key'
import ISSUE                                from 'common/ISSUE'
import State                                from '@entities/issue/state'
import URLS                                 from 'constants/URLS'

import Medias                               from 'client/features/issues/components/details/Medias.jsx'
import Address                              from 'client/features/overlay-view/components/Address.jsx'
import Overlay                              from 'client/features/common/components/Overlay.jsx'
import Requestor                            from 'client/features/issues/components/details/Requestor.jsx'
import Nears                                from 'client/features/issues/components/details/Nears.jsx'
import Group                                from 'client/features/issues/components/details/Group.jsx'
import Description                          from 'client/features/issues/components/details/Description.jsx'
import History                              from 'client/features/issues/components/details/history/History.jsx'
import Data                                 from 'client/features/issues/components/details/Data.jsx'
import MainTitle                            from 'client/features/issues/components/details/MainTitle.jsx'
import Map                                  from 'client/features/common/components/Map.jsx'
import PublicMessages                       from 'client/features/issues/components/details/PublicMessages.jsx'
import Ungroup                              from 'client/features/issues/components/modals/Ungroup.jsx'
import MapModal                             from 'client/features/issues/components/modals/Map.jsx'
import Hashtags                             from 'client/features/issues/components/common/Hashtags.jsx'
import IssueActions                         from 'client/features/issues/components/details/Actions.jsx'
import IssueUpdatesWrapper                  from 'client/features/issues/containers/UpdatesWrapper.jsx'
import FocusBlur                            from "@cComponents/focusBlur.jsx";
import FilesOverlay                         from 'client/features/common/components/FilesOverlay.jsx'

import Assignment   from "@entities/assignments/assignment";
import I18N                                 from "@uBehaviour/i18n";
import { jsonFetch }                        from "@wLib/fetch";

import Valorizations                        from '@entities/valorizations/valorizations';
import Money from "@cComponents/money";

import "./Issue.css";
import Application from '@universal/behaviour/application'
import Acl from '@uBehaviour/acl'
import { useLocation } from '@universal/features/router';
import isEmptyAssignment from '@uBusiness/lib/issues/isEmptyAssignment';
dayjs.extend(relativeTime);

class IssueDetails extends React.Component {
  constructor(props) {
    super(props)
    this.archive             = this.archive.bind(this)
    this.addressChange       = this.addressChange.bind(this)
    this.displayNearIssue    = this.displayNearIssue.bind(this)
    this.tagChange           = this.tagChange.bind(this)
    this.descriptionChange   = this.descriptionChange.bind(this)
    this.removeHastag        = this.removeHastag.bind(this)
    this.removePDF           = this.removePDF.bind(this)
    this.unmerge             = this.unmerge.bind(this)
    this.update              = this.update.bind(this)
    this.updatePicture       = this.updatePicture.bind(this)
    this.showFileOverlay     = this.showFileOverlay.bind(this)
    this.hideFileOverlay     = this.hideFileOverlay.bind(this)
    this.onFileChange        = this.onFileChange.bind(this)
    this.contactRequestor    = this.contactRequestor.bind(this)
    this.updateRequestor     = this.updateRequestor.bind(this)
    this.setMemo             = this.setMemo.bind(this)
    this.getIssue            = this.getIssue.bind(this)
    this.addPublicComment    = this.addPublicComment.bind(this)
    this.editPublicComment   = this.editPublicComment.bind(this)
    this.deletePublicComment = this.deletePublicComment.bind(this)
    this.unlinkOne           = this.unlinkOne.bind(this)
    this.linkOne             = this.linkOne.bind(this)
    this.displayMapModal     = this.displayMapModal.bind(this)
    this.displayUngroupModal = this.displayUngroupModal.bind(this)
    this.searchHashtag       = this.searchHashtag.bind(this)
    this.setCurrentTab       = this.setCurrentTab.bind(this)
    this.hideFileOverlay     = this.hideFileOverlay.bind(this)
    this._addAssignment      = this._addAssignment.bind(this);
    this.acceptedMimeTypes   = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']

    this.state               = {
      dragOver: false,
      showMapModal: false,
      showFilesOverlayButton: false,
      currentUnlink: null,
      showUngroupModal: false,
      mediasErrors: {},
      currentTab: 'planification'
    }
  }
  componentWillUnmount(){
    this.props.dispatch({ type: 'clear_current_issue_on_detail_close' })
  }
  componentDidMount() {
    this.getIssue()
  }

  setCurrentTab(currentTab = 'planification') {
    this.setState({ currentTab })
  }

  /*@keydown('left', 'right')
    navBetweenIssues(event) {
        let side = 1
        if (event.key && event.key === 'ArrowLeft')
            side = -1
        const indexOfCurrent = this.props.issues.list.findIndex(id => id === this.props.issue._id)
        if (this.props.issues.list[indexOfCurrent + side]) {
            const issue = getIssueFromAll(this.props.issues.all, this.props.issues.list[indexOfCurrent + 1])
            if (issue)
                this.props.goToIssue(issue)
        }
    }*/

    goToIssueAndCloseModal = (issue) => {
      this.setState({ showMapModal: false })
      this.props.goToIssue(issue)
    }

    componentDidUpdate(prevProps) {
      const prevId = prevProps.match.params.id
      const newId = this.props.match.params.id
      if (prevId && newId && prevId !== newId) {
        this.getIssue()
      }
    }

    getIssue() {
      this.props.dispatch(IssueRequest.get.action({ params: this.props.match.params }))
    }

    unlinkOne(issue) {
      this.update('unlink', {
        payload: { ungroupIssue: this.state.currentUnlink._id }
      })
      this.setState({
        currentUnlink: null,
        showUngroupModal: false
      })
    }

    linkOne(issue) {
      this.props.dispatch(IssueRequest.put.action({
        payload: {
          group: this.props.issue.group._id
        },
        params: {
          id: issue._id,
          type: 'link'
        }
      }))
    }

    update(type, data = {}) {
      if (!type)
        throw new Error('Update issue: missing type')
      const req = {
        ...data,
        params: {
          id: this.props.issues.current,
          type
        }
      }
      this.props.dispatch(IssueRequest.put.action(req))
    }

    unmerge() {
      this.update('unmerge')
    }

    addPublicComment(values) {
      this.update('publicComment', {
        payload: {
          ...values
        }
      })
    }

    archive() {
      this.update('archive', {})
    }

    editPublicComment(values) {
      this.update('editPublicComment', {
        payload: {
          ...values
        }
      })
    }

    deletePublicComment(comment) {
      this.update('deletePublicComment', {
        payload: {
          comment: comment._id ? comment._id : comment
        }
      })
    }

    displayMapModal() {
      /*if (this.props.issue.type === 'building')
            return this.props.dispatch(push(makeEndpoint(
                URLS.buildings.show.replace('/**', this.props.location.pathname), { id: this.props.issue.building._id }
            )))*/
      this.setState({
        showMapModal: !this.state.showMapModal
      })
    }

    showMoreIssues = () => {
      const issueType = this.props.issue.type
      if (issueType === 'publicSpace')
        return this.displayMapModal()

      const filters = {
        state: ['open', 'planned', 'in_progress']
      }

      if (issueType === 'building') {
        filters.search = { building: this.props.issue.building.value }
        this.props.dispatch(setIssuesSearchValue({ value: this.props.issue.building.label, force: true }))
      } else if (issueType === 'patrimony') {
        filters.search = { patrimony: this.props.issue.patrimony.value }
        this.props.dispatch(setIssuesSearchValue({ value: this.props.issue.patrimony.label, force: true }))
      }

      this.props.setLocation('/issues');
      this.props.dispatch(loadFilters(filters))
    }

    addressChange(values) {
      this.setState({
        showMapModal: false
      })
      this.update(this.props.issue.type === 'publicSpace' ? 'address' : this.props.issue.type === 'building' ? 'building' : 'patrimony', {
        payload: {
          ...values
        }
      })
    }

    displayNearIssue(issueId) {
      this.props.dispatch(displayNearIssue(issueId))
    }

    tagChange(tag) {
      this.update('tag', { payload: { tag } })
    }

    descriptionChange(description) {
      this.update('description', { payload: { description } })
    }

    updatePicture(data, picture, type) {
      this.update(_.camelCase(`picture-${type}`), { payload: { rotate: data, picture } })
    }

    removePDF(pdf) {
      this.update('removePDF', { payload: { pdf } })
    }

    contactRequestor(payload) {
      this.update('contactRequestor', { payload })
    }

    updateRequestor(requestor) {
      this.update('requestor', { payload: { requestor } })
    }

    removeHastag(hashtag) {
      this.update('removeHashtag', { payload: { hashtag } })
    }

    setMemo(comment, mentionned, hashtags) {
      this.update('internalNote', { payload: { comment, users: mentionned, hashtags } })
    }

    isFileTypeAllowed(file) {
      return this.acceptedMimeTypes.includes(file.type);
    }

    onFileChange(files, errors) {
      if(files.some((file) => !this.isFileTypeAllowed(file))){
        this.props.messageService.send('error', <I18N>issue_file_format_error</I18N>);
        
        this.setState({
          dragOver: false,
          showFilesOverlayButton: false
        })  
        return;
      }

      if (errors && errors[0]) {
        const { mediasErrors } = this.state
        mediasErrors.files = true
        this.setState({
          mediasErrors
        })
        setTimeout(() => {
          mediasErrors.files = false
          this.setState({ mediasErrors })
        }, 6000)
      }
      this.setState({
        dragOver: false,
        showFilesOverlayButton: false
      })
      if (files && files[0]) {
        this.update('files', {
          files,
          payload: {
            callId: randomKey.generate(),
            putType: 'files'
          }
        })
      }
    }

    keyPress(event) {
      if (event.keyCode === 27)
        this.close(event)
    }

    close(p, e) {
      if (e && e.stopPropagation)
        e.stopPropagation()
      this.props.goBack();
      this.props.dispatch(closeIssue({ issue: this.props.issue }))
    }

    displayUngroupModal(currentUnlink) {
      this.setState({
        currentUnlink,
        showUngroupModal: !this.state.showUngroupModal
      })
    }

    getNearsTitle(type) {
      let title = 'issue_details_title_near'
      if (type === 'building')
        title = 'nears_building'
      else if (type === 'patrimony')
        title = 'nears_patrimony'
      return T.translate(title)
    }

    searchHashtag(hashtag) {
      this.props.dispatch(setIssuesSearchValue({ value: hashtag.label }))
      this.props.dispatch(setIssuesFiltersValue({
        key: 'search',
        value: { hashtag: hashtag._id },
        uniq: true,
        searchValue: hashtag.label
      }))
      this.close()
    }

    showFileOverlay(showFilesOverlayButton = false) {
      if (this.state.dragOver) return
      this.setState({ dragOver: true, showFilesOverlayButton: !!showFilesOverlayButton })
    }

    hideFileOverlay(){
      if (!this.state.dragOver) return;
      this.setState({ dragOver: false })
    }
    _addAssignment(){
      jsonFetch(`/issues/${ this.props.issue._id }/assignments`, "POST");
    }

    _openCalendar = (date) => {
      this.props.dispatch(ActionCalendar.setDate({
        date: date
      }));
      this.props.setLocation('/planning');
    }

    render() {
      const { issues, issue, session, confirmations, acl } = this.props
      if (issues.fetchOneError)
        return(<Overlay text={ T.translate('error_404_issue_not_found') }/>)
      if (!issue) return (<Overlay loader={ true } randomText/>)

      const currentNear = getIssueFromAll(issues.all, this.props.issues.currentNear)
      const nears = issue.nears || []

      const teamsField = cloneDeep(issues.filters.fields.find(field => field.key === 'teams'))
      if (teamsField)
        teamsField.attributes = teamsField.attributes.filter((attribute) => attribute.value)

      const isAssignment = !!this.props.assignmentId;
      const haveTheRightToReadAssignments = acl.connectedUserIsAllow('assignments', 'read');
      const haveTheRightToReadValorizations = acl.connectedUserIsAllow('valorizations', 'read');

      const readOnly = !this.props.acl.connectedUserIsAllow('assignments', 'manage');
      
      const assignments = issue.assignments.filter(assignment => {
        const nbrdisplayedDays = session.user.settings.views?.workOrders.displayedIssues || 0;
        return (
          !(isEmptyAssignment(assignment) && readOnly) &&
          !(acl.connectedUserIsAllow("issues", "isLimitedInDayToSeeAssignment")
            && nbrdisplayedDays > 0
            && dayjs(assignment.scheduledFrom) > dayjs().add(nbrdisplayedDays, "day")
          )
        );
      });

      let assignmentComponent = null;
      if(assignments.length === 0) {
        assignmentComponent = (
          <div style={{ color: "lightgray", fontStyle: "italic" }}>
            <I18N>assignment_none</I18N>
          </div>
        );
      }
      else {
        assignmentComponent = (
          <div>
            {assignments.map((assignment, index) => (
              <Assignment 
                openCalendar={ this._openCalendar }
                firstAndEmpty={issue.assignments.length === 1 && isEmptyAssignment(issue.assignments[0])}
                isAssignment={ isAssignment }
                assignment={ assignment }
                issue={ issue._id }
                deadline={ issue.deadline }
                tenant={ issue.tenant }
                readOnly={!isAuthorized(issue, 'scheduledFrom')}
                mustBeValorized={issue.original.mustBeValorized}
                canBeDeleted={(!isEmptyAssignment(assignment) && index === 0) || index !== 0}
              />
            ))}
            { isAuthorized(issue, 'scheduledFrom') && !isEmptyAssignment(issue.assignments[issue.assignments.length-1]) && !isAssignment
              ? (
                <div className="bs-old-issue-assignment-add">
                  <span className="bs-button" onClick={ this._addAssignment }>
                  <span className="fa fa-plus"/>&nbsp;<I18N>assignment_add</I18N>
                  </span>
                </div>)             
              : null
            }
          </div>
        )
      };

      return (
        <FocusBlur.Context className='c-issue-detail'>
          <Dropzone
            accept={ ISSUE.files.accepted }
            disableClick
            onChange={ this.onFileChange }
            onDragEnter={ this.showFileOverlay }
            onDragLeave={ this.hideFileOverlay }
            onDrop={ this.onFileChange }
            className='c-issue-detail__dropzone dropzone dropzone'
            activeClassName='dropzone-active'
          >
            <>
              {
                (this.state.dragOver) &&
                  <FilesOverlay
                    showButton={ this.state.showFilesOverlayButton }
                    onDrop={ this.onFileChange }
                    close={ this.hideFileOverlay }
                  />
              }

              {
                issue.merged &&
                  <Overlay text={
                    <span>
                      { T.translate('issueMerged') }&nbsp;
                      <a onClick={ this.props.goToIssue.bind(this, issue.merged) }>{ issue.merged.bsId }</a>
                      <br />
                      { isAuthorized(issue, 'merge') &&
                      <a className='button-a orange' onClick={ this.unmerge }>{ T.translate('reopen') }</a>
                      }
                      <a className='button-a' onClick={ () => this.close() }> {T.translate('issue_detail_merged_close')} </a>
                    </span>
                  }/>
              }
              <article className='c-issue-detail__wrapper'>
                {
                  issues.newInProgress && <Overlay loader={ true } text={ T.translate('please_wait') }/>
                }
                <div className='c-issue-detail__main'>
                  <div className='o-panel'>
                    <div className='c-issue-detail__main-wrapper'>
                      <div className='c-issue-detail__content'>
                        <div className='c-issue-detail__title'>
                          <MainTitle
                            issue={ issue }
                            session={ session }
                            tagChange={ this.tagChange }
                            confirmations={ confirmations }
                          />
                        </div>
                        <div className='c-issue-detail__hashtags'>
                          <Hashtags
                            hashtags={ issue.hashtags }
                            bsId={ issue.bsId }
                            removeHastag={ this.removeHastag }
                            disabled={ !isAuthorized(issue, 'hashtags') }
                          />
                        </div>
                        <div className='bs-old-issue-detail-state'>
                        <Acl.If resource='issues' action='manage'>
                          <Acl.Then>
                            <State.SelectAndComment issue={ issue.original } />
                          </Acl.Then>
                          <Acl.Else>
                            <State.Standart state={ issue.original.state } />
                          </Acl.Else>
                        </Acl.If>
                        </div>
                        <div className='c-issue-detail__description'>
                          <Description
                            value={ issue.description }
                            onChange={ this.descriptionChange }
                            confirmation={ confirmations.description }
                            disabled={ !isAuthorized(issue, 'description', "update") }
                            mentions= {
                              [
                                {
                                  type: 'tag',
                                  trigger: '#',
                                  data: setMentionsHastags(issue, session.user.resource && session.user.resource.hashtags, true),
                                  renderSuggestion: (tag, search, highlightedDisplay) => <span>{ highlightedDisplay }</span>
                                }
                              ]
                            }
                          />
                        </div>
                        <div className='c-text-light u-margin-bottom-tiny'>
                          <T.span text='created_at'/>
                          &nbsp;{dayjs(issue.created).fromNow()}
                          &nbsp;{T.translate('by').toLowerCase()} {issue.requestor.labelShort}
                        </div>
                        <div
                          className={ classNames('c-issue-location u-cursor-pointer', { 'c-issue-detail__location': issue.latitude }) }
                          onClick={ this.displayMapModal }
                        >
                          {
                            issue.latitude && issue.longitude &&
                              <Map
                                latitude={ issue.latitude }
                                longitude={ issue.longitude }
                                marker={ issue.marker }
                                user={ session.user }
                                currentNear={ currentNear }
                              />
                          }
                          <Address
                            building={ issue.building }
                            place={ issue.place }
                            locationInfo={ issue.locationInfo }
                            roadInfo={ issue.roadInfo }
                            address={ issue.subject }
                            subAddress={ (issue.type === 'building' || issue.type === 'patrimony') && issue.addressShort }
                            inline
                            type={ issue.type }
                            className={ classNames('c-issue-location__address', { 'u-padding-none': !issue.latitude, 'u-border-bottom': !issue.latitude, 'u-margin-bottom': !issue.latitude, 'u-position-relative': !issue.latitude }) }
                          />
                        </div>
                        <div className='c-issue-detail__data u-margin-top'>
                          <Data />
                        </div>
                        {
                          (haveTheRightToReadAssignments || haveTheRightToReadValorizations) &&
                          <div className='u-margin-top'>
                            <div className='c-tabs'>
                              <div className='c-tabs__nav'>
                                <div className='c-tabs__headings'>
                                  <div
                                    className={ classNames('c-tab-heading u-text-align-left u-padding-left-none', { 'c-tab-heading--active': this.state.currentTab === 'planification' }) }
                                    onClick={ this.setCurrentTab.bind(null, 'planification') }
                                  >
                                    { /*<FontAwesome name='calendar' />&nbsp; */ }
                                    { T.translate('work_order') }
                                  </div>
                                  {
                                    haveTheRightToReadValorizations &&
                                      <div
                                        className={ classNames('c-tab-heading', { 'c-tab-heading--active': this.state.currentTab === 'valorization' }) }
                                        onClick={ this.setCurrentTab.bind(null, 'valorization') }
                                      >
                                        { T.translate(!issue.original.$valorizationsTotal ? 'add_valorization' : 'valorization') }&nbsp;
                                        {
                                          (issue.original.withValorization && issue.original.$valorizationsTotal > 0) &&
                                          <Acl.If resource="valorizations" action="readCost">
                                            (<Money value={issue.original.$valorizationsTotal} />)
                                          </Acl.If>
                                        }
                                      </div>
                                  }
                                </div>
                              </div>
                            </div>
                            <div
                              className={ classNames('c-tabs__tab', { 'c-tabs__tab--active': this.state.currentTab === 'planification' }) }
                            >
                              {
                                this.state.currentTab === 'planification' && assignmentComponent
                              }
                            </div>
                            {
                              haveTheRightToReadValorizations &&
                                <div
                                  className={ classNames('c-tabs__tab', { 'c-tabs__tab--active': this.state.currentTab === 'valorization' }) }
                                >
                                  <div style={{ display: this.state.currentTab === 'valorization' ? 'block' : 'none' }}>
                                  {[
                                    <Valorizations 
                                      key={`Issue::${ issue._id }`}
                                      issue={ issue.original }
                                      isDisplay={ this.state.currentTab === 'valorization' }
                                    />
                                  ]}
                                  </div>
                                </div>
                            }
                          </div>
                        }
                      </div>
                      <div className='bs-old-issue-detail-left'>
                        <Medias
                          updatePicture={ this.updatePicture }
                          removePDF={ this.removePDF }
                          showDropOverlay={ this.showFileOverlay }
                          confirmation={ confirmations.files }
                          errors={ this.state.mediasErrors }
                          cover={ issue.cover }
                          noCover={ issue.noCover }
                          pictures={ issue.pictures }
                          pdfs={ issue.pdfs }
                          tag={ issue.tag }
                          bsId={ issue.bsId }
                          issueId={ issue._id }
                        />
                        {
                          isAuthorized(issue, 'requestor', 'read') &&
                            <Requestor
                              issue={ issue }
                              user={ session.user }
                              contactRequestor={ this.contactRequestor }
                              updateRequestor={ this.updateRequestor }
                              confirmation={ confirmations.requestor }
                            />
                        }
                        {
                          issue.group && issue.group.issues && issue.group.issues[0] && isAuthorized(issue, 'group', "read") &&
                            <Group
                              group={ issue.group }
                              unlink={ isAuthorized(issue, 'duplicate') ? this.displayUngroupModal : null }
                              issues={ issue.group.issues.map((issue) => getIssueFromAll(this.props.issues.all, issue._id)).filter((groupIssue) => !!groupIssue && groupIssue?._id !== issue._id) }
                              goToIssue={ this.props.goToIssue }
                              loading={ this.props.issues.currentLoading }
                            />
                        }
                        {
                          issue.group && issue.group.issues && issue.group.issues[0] &&
                            <Ungroup
                              unlink={ this.unlinkOne }
                              show={ this.state.showUngroupModal }
                              onHide= { this.displayUngroupModal }
                              issue={ this.state.currentUnlink }
                              group={ issue.group }
                            />
                        }

                        {
                          isAuthorized(issue, 'nears', 'read') &&
                            <Nears
                              issues={ nears }
                              type={ issue.type }
                              title={ this.getNearsTitle(issue.type) }
                              displayNearIssue={ this.displayNearIssue }
                              goToIssue={ this.props.goToIssue }
                              loading={ this.props.issues.currentLoading && (!issue.nears || !issue.nears[0]) }
                              displayMapModal={ this.displayMapModal }
                              showMoreIssues={ this.showMoreIssues }
                              merge={ isAuthorized(issue, 'merge') ? this.props.displayMergeModal : null }
                              //link={ issue.group ? this.linkOne : null }
                            />
                        }
                        {
                          issue.original.allowPublicComment &&
                            <PublicMessages
                              user={ session.user }
                              comments={ issue.comments }
                              display={ issue.display }
                              edit={ issue.original.allowPublicComment }
                              addPublicComment={ this.addPublicComment }
                              editPublicComment={ this.editPublicComment }
                              deletePublicComment={ this.deletePublicComment }
                            />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='c-issue-detail__meta'>
                  <IssueActions
                    displayMergeModal={ this.props.displayMergeModal }
                    displayLinkModal={ this.props.displayLinkModal }
                  />
                  <History
                    issue={ issue }
                    user={ session.user }
                    loading={ this.props.issues.currentLoading && !issue.logs }
                    setMemo={ this.setMemo }
                    colleagues={ this.props.session.allColleagues }
                    confirmations={ confirmations }
                    goToIssue={ this.props.goToIssue }
                  />
                </div>
              </article>
              {
                this.state.showMapModal &&
                  <MapModal
                    issue={ issue }
                    onHide={ this.displayMapModal }
                    show={ this.state.showMapModal }
                    nears={
                      nears.map((near) => ({ ...near, ...getIssueFromAll(this.props.issues.all, near._id) }))
                    }
                    displayNearIssue={ this.displayNearIssue }
                    currentNear={ currentNear }
                    goToIssue={ this.goToIssueAndCloseModal }
                    loading={ this.props.issues.currentLoading }
                    onSubmit={ this.addressChange }
                    session={ session }
                    nearsTitle={ this.getNearsTitle(issue.type) }
                    merge={ isAuthorized(issue, 'merge') ? this.props.displayMergeModal : null }
                    showMoreIssues={ this.showMoreIssues }
                  />
              }
            </>
          </Dropzone>
        </FocusBlur.Context>
      )
    }
}

const IssueConnected = Application.Service.forward(['acl', ['message', 'messageService']], IssueUpdatesWrapper(
  (props) => {
    const [, setLocation, goBack] = useLocation();
    return <IssueDetails { ...props } setLocation={ setLocation } goBack={ goBack }/>
  }
));

export default IssueConnected;