import React         from "react";
import Filter        from "@cComponents/filter";
import FilterManager from "./manager";
import ScrollBar     from "@cComponents/scrollBar";
import Slot          from "@uComponents/slot";
import Display       from "@uComponents/displayIf";

import "./list.css";

const _Filter   = Slot();
const Header    = Slot();
const SubHeader = Slot();
const List      = Slot();

const FilteredList = ({ myFilterTitle, type,  ...props }) => (
  <Filter.Aggregator default={ props.default } ref={ props.filterRef }>
    <div className="bs-business-container">
      <div className="bs-business-container-filters">
        <div className="bs-business-container-filters-container">
          <div>
            <ScrollBar>
              <div className="bs-business-container-filters-content">
              { _Filter.get({ props }) }
              </div>
            </ScrollBar>
          </div>
        </div>
        <div className="bs-business-container-filters-header">
          <ScrollBar>
            <div className="bs-business-container-filters-header-viewport">
              <Filter.Bulk />
              {type && (
                <FilterManager type={type} title={ myFilterTitle }/>
              )}
            </div>
          </ScrollBar>
        </div>
      </div>
      <div className="bs-business-container-list">
        <div className="bs-business-container-list-header">
          { Header.get({ props })}
        </div>
        { SubHeader.get({ props })}
        <div className="bs-business-container-list-content">
          <Filter.Subject>
          {composeQuery => {
            const lists = List.props({ props }, true);
            return (
              <Display.If condition={lists.length === 1}>
                <Display.Then>
                  {() => {
                    const listProps     = List.props({ props });
                    const withScrollBar = listProps.scrollBar === undefined || listProps.scrollBar;
                    return (
                      <Display.If condition={ withScrollBar }>
                        <Display.Then>
                        {() => (
                          <ScrollBar.ListController>
                            { List.get({ props })(composeQuery) }
                          </ScrollBar.ListController>
                        )}
                        </Display.Then>
                        <Display.Else>
                        {() => List.get({ props })(composeQuery) }
                        </Display.Else>
                      </Display.If>
                    )
                  }}
                </Display.Then>
                <Display.Else>
                  {() => (
                    <ScrollBar.MultiListsController>
                      {lists.map(list => (
                        <ScrollBar.MultiListsController.List
                          getHeight={list.getHeight}
                          load={list.load}
                          isLoadable={list.isLoadable}
                        >
                          {list.children(composeQuery)}
                        </ScrollBar.MultiListsController.List>                        
                      ))}
                    </ScrollBar.MultiListsController>
                  )}
                </Display.Else>
              </Display.If>
              
            )
          }}
          </Filter.Subject>
        </div>
      </div>
    </div>
  </Filter.Aggregator>
);

FilteredList.Filter     = _Filter;
FilteredList.Header     = Header;
FilteredList.SubHeader  = SubHeader;
FilteredList.List       = List;

export default FilteredList;
