import Application                from "@uLib/application";

import HttpSerivce                from "@cServices/http";
import PersistentStorageService       from "@cServices/persistentStorage";
import TemporaryStorageService       from "@cServices/temporaryStorage";
import UrlSerivce                 from "@cServices/url";
import MessageService             from "@cServices/message";
import Geolocation                from "@cServices/geolocation";
import NavigatorService           from "@cServices/navigator";
import GoogleMapService           from "@cServices/googleMap";
import JwtService                 from "@cServices/jwt";
import ResourcesService           from "@cServices/resources";
import TooltipService             from "@cServices/tooltip";
import TestError                  from "@cServices/testError";
import Networking                 from "@cServices/networking";
import DownloadService            from "@cServices/download";

import ApiService                 from "@uServices/api";
import ConfigurationService       from "@uServices/configuration";
import CurrentTenantService       from "@uServices/currentTenant";
import TenantGeolocationService   from "@uServices/tenantGeolocation";
import I18nService                from "@uServices/i18n";
import RepositoryService          from "@uServices/repository";
import SessionService             from "@uServices/session";
import AclService                 from "@uServices/acl";
import PushService                from "@uServices/push";
import PagerService               from "@uServices/pager";
import ReduxStoreService          from "@uServices/reduxStore";
import FileService                from "@uServices/file";
import ReleaseService             from "@uServices/release";
import Campaign                   from "@uServices/campaign";
import BusinessNotificationService from "@uServices/businessNotification";
import LoginAsSessionManagerService  from "@uServices/loginAsSessionManager";
import DefaultStorageService       from "@uServices/defaultStorageService";
import UserStorageStrategyService from "@uServices/userStorageStrategy";

import UsersService               from "@uServices/business/users";

import ModelLoaders               from "@uBusiness/model";

import WeatherService             from "@services/weather";

import configuration              from "@wConfig/value";
import adminDic                   from "@wConfig/i18n";
import defaultDic                 from "@cConfig/i18n";
import universalDic               from "@uConfig/i18n";


import ReleaseHelper              from "@uHelpers/release";
import UsersHelper                from "@uHelpers/users";
import MapLayerHelper             from "@uHelpers/mapLayer";
import FileHelper                 from "@uHelpers/file";
import PrintHelper                from "@uHelpers/print";
import AssistanceHelper           from "@uHelpers/assistance";
import FilterHelper               from "@uHelpers/filter";
import ValorizationHelper         from '@uHelpers/valorization';
import TenantHelper               from '@uHelpers/tenant';
import CalendarHelper             from '@uHelpers/calendar';
import GeniallyHelper             from "./helpers/genially";

import moment                     from "moment";
import "moment/locale/nl";
import "moment/locale/fr";

import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import ClipboardService from "@common/services/clipboard";
import Text from "@common/components/text";
dayjs.extend(relativeTime);
dayjs.extend(duration);

const languages = [{ iso: "fr-FR", bs: "fr", label: "Français" }];

window.document.title = "BetterStreet Admin";

const application = new Application();

const navigatorService = new NavigatorService();

const services = [
  new DefaultStorageService(),
  new PersistentStorageService(),
  new TemporaryStorageService(),
  new Networking(),
  new ResourcesService(),
  new HttpSerivce(),
  new JwtService(),
  new UrlSerivce(),
  new ApiService(),
  new ConfigurationService(configuration),
  new CurrentTenantService(),
  new I18nService([universalDic, defaultDic, adminDic], languages, languages[0], Text),
  new RepositoryService(ModelLoaders),
  new LoginAsSessionManagerService(),
  new SessionService("pro", "bsAdminToken"),
  new FileService(Math.pow(1024, 3) * 50, ['image/png', 'image/jpeg', 'application/pdf']),
  new PushService((uri) => new Promise((resolve, reject) => {
    const url             = `${uri.protocol}://${uri.host}${uri.path}/socket.io.js`;
    const script          = document.createElement("script");
    script.type           = "text/javascript";
    script.src            = url;
    script.referrerpolicy = "origin";
    script.async          = true;
    script.defer          = true;
    script.onload         = () => {
      resolve(window.io);
    };
    document.head.appendChild(script);
  })),
  new ClipboardService(),
  new AclService(),
  new PagerService(),
  new MessageService(),
  new ReduxStoreService(),
  new Geolocation(),
  new TenantGeolocationService(),
  new GoogleMapService(),
  new UsersService(),
  new ReleaseService("admin", "web"),
  new TooltipService(),
  new WeatherService(),
  new DownloadService(),
  new Campaign(),
  new UserStorageStrategyService("userPersistentStorage", "persistentStorage"),
  new UserStorageStrategyService("userTemporaryStorage", "temporaryStorage"),
  new UserStorageStrategyService("userStorage", "storage"),
  navigatorService,
  new TestError()
];

if(!navigatorService.navigator.current.startsWith("/print")){
  services.push(new BusinessNotificationService(30));
}

application.initializeServices(services);

application.addServiceAlias("simple-storage", "persistentStorage");

application.initializeHelpers([
  new CalendarHelper(),
  new ReleaseHelper(),
  new UsersHelper(),
  new MapLayerHelper(),
  new FileHelper(),
  new PrintHelper(),
  new AssistanceHelper(),
  new FilterHelper(),
  new ValorizationHelper(),
  new TenantHelper(),
  new GeniallyHelper()
]);

application.start();

moment.locale(application.getService("i18n").currentLanguage.bs);
moment.duration().locale(application.getService("i18n").currentLanguage.bs);
dayjs.locale(application.getService("i18n").currentLanguage.bs);
dayjs.duration().locale(application.getService("i18n").currentLanguage.bs);
window.document.querySelector('html').lang = application.getService("i18n").currentLanguage.bs;

application.getService("i18n").onServiceUpdated.addListener({
  handleEvent: (i18nService) => {
    moment.locale(i18nService.currentLanguage.bs);
    moment.duration().locale(i18nService.currentLanguage.bs);
    dayjs.locale(i18nService.currentLanguage.bs);
    dayjs.duration().locale(i18nService.currentLanguage.bs);
    window.document.querySelector('html').lang = i18nService.currentLanguage.bs;
  }
});

window.application = application;
window.onclose = () => application.stop();



//A supprimer quand ancien système sera supprimé
const configurationService = application.getService("configuration");
window.__CONFIG = {
  apiHost: configurationService.get('api_uri_v1'),
  apiV2Host: configurationService.get('api_uri'),
  ws: configurationService.get('socketio_uri'),
  gmap: configurationService.get('google_api_key'),
  fileStorage: {
    url: configurationService.get('file_storage_read_url')
  }
};

window.__LANG   = "fr";
window.__CLIENT = true;
window.__SERVER = false;

export default application;