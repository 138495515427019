import React, { FunctionComponent, PropsWithChildren } from "react";
import _ from "lodash";

import { Section } from "@common/components/section";
import T from "@universal/behaviour/i18n";
import Field from "@common/components/field";
import Form from "@universal/behaviour/form";
import Input from "@common/components/input";
import Display from "@universal/components/displayIf";
import Layout from "@common/components/layout";
import TabList from "@common/components/tab";
import Modal from "@common/components/modal";
import SelectableList from "@common/components/selectableList";
import Button from "@common/components/button";
import ModalTemplate from "@common/components/template/modal/standart";

import TenantItem from "@entities/tenant/item";

import withMouseEventStopped from "@common/hoc/withMouseEventStopped";

import useService from "@universal/behaviour/hooks/useService";
import useOpenCloseToggle from "@universal/behaviour/hooks/useOpenCloseToggle";
import useRepository from "@universal/hooks/useRepository";
import { useTabControl } from "@common/components/tab/list";

import AclService from "@universal/services/acl";
import I18nService from "@universal/services/i18n";
import { Role } from "@universal/lib/acl";

import Tenant from "@universal/types/business/Tenant";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import ObjectId from "@universal/types/technic/ObjectId";
import {
  AuthentificationProviderUsername,
  isAgent,
  isUserCollaborator,
  isUserPro,
  UserCollaborator,
  UserCollaboratorRoles,
  UserDiscriminators,
  UserPro,
  UserProAclAllowedRoles,
  UserProTenant
} from "@universal/types/business/User";


import './form.css';
import CurrentTenantService from "@universal/services/currentTenant";
import Text, { Style } from "@common/components/text";
import Tooltip from "@common/components/tooltip";
import useAsyncValidationWithQuestion, { QuestionProps } from "@universal/behaviour/hooks/useAsyncValidationWithQuestion";
import BuildingItem from "@root/entities/building/item";
import Selected from "@common/components/item/selected";
import ItemTiny from "@common/components/item/tiny";
import Query, { combinate } from "@universal/lib/query";
import ApiService from "@universal/services/api";
import Building from "@universal/types/business/Building";
import SessionService from "@universal/services/session";

import { buildValidator } from "@universal/lib/validator";
import Nudge from "@common/components/nudge";
import RepositoryService from "@universal/services/repository";


const ValidateButton = withMouseEventStopped(Button.Stylized.positive);
const CancelButton = withMouseEventStopped(Button.Stylized.negative);


type UserProps = (UserCollaboratorProps | UserProProps);

type AuthType = {
  authentificationProviders: (AuthentificationProviderUsername & {
    type: "email" | "username" | "none";
  })[];
}

type UserCollaboratorProps = {
  user: UserCollaborator & AuthType;
}

type UserProProps = {
  user: UserPro & AuthType;
}


const isNew = (user: UserPro | UserCollaborator | undefined): boolean => {
  return user?._id === undefined;
}

const Marger: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className="bs-users-form-marger">
    { children }
  </div>
);

const Avatar: FunctionComponent = () => (
  <Section>
    <Section.Title>
      <T>users_form_user_avatar_section_title</T>
    </Section.Title>
    <Section.Content>
      <Form.Simple.InputAdapter name="avatar">
      {(value, set) => (
        <Input.File
          limit={ 1 }
          value={ value ? [value] : [] }
          onChange={ (value: ObjectId<"File">) => value.length ? set(value[0]) : set(null) }
          width="100%"
        />
      )}
      </Form.Simple.InputAdapter>
    </Section.Content>
  </Section>
);

const GeneralInformations: FunctionComponent<UserProps> = ({ user }) => {
  const acl = useService<AclService>("acl");
  const manageCollaborators = acl.connectedUserIsAllow("users", "manageCollaborators");
  return (
    <Section>
      <Section.Title>
        <T>users_form_user_generalInformations_section_title</T>
      </Section.Title>
      <Section.Content>
        <Form.Simple.Adapter name="firstname">
        {(errors, value, set) => (
          <Field.Display errors={ errors } required>
            <Field.Label>
              <T>users_form_user_generalInformations_firstname_label</T>
            </Field.Label>
            <Field.Input>
              <Input.Text value={ value } onChange={ set }/>
            </Field.Input>
          </Field.Display>
        )}
        </Form.Simple.Adapter>
        <Form.Simple.Adapter name="lastname">
        {(errors, value, set) => (
          <Field.Display errors={ errors } required>
            <Field.Label>
              <T>users_form_user_generalInformations_lastname_label</T>
            </Field.Label>
            <Field.Input>
              <Input.Text value={ value } onChange={ set }/>
            </Field.Input>
          </Field.Display>
        )}
        </Form.Simple.Adapter>
        <Display.If condition={ user.discriminator !== "pro" }>
          <Form.Simple.Adapter name="disabled">
          {(errors, value, set) => (
            <Field.Display errors={ errors }>
              <Field.Label>
                <T>users_form_user_generalInformations_disabled_label</T>
              </Field.Label>
              <Field.Input>
                <Input.Radio.Btn value={ value } onChange={ set } inline>
                  <Input.Radio.Value value={ false }><T>users_form_user_generalInformations_disabled_false</T></Input.Radio.Value>
                  <Input.Radio.Value value={ true }><T>users_form_user_generalInformations_disabled_true</T></Input.Radio.Value>
                </Input.Radio.Btn>
              </Field.Input>
            </Field.Display>
          )}
          </Form.Simple.Adapter>
        </Display.If>
        <Display.If condition={ isNew(user) && manageCollaborators }>
          <Form.Simple.Adapter name="discriminator">
          {(errors, value, set) => (
            <Field.Display errors={ errors }>
              <Field.Label>
                <T>users_form_user_generalInformations_discriminator_label</T>
              </Field.Label>
              <Field.Input>
                <Input.Radio.Btn value={ value } onChange={ set } inline>
                  <Input.Radio.Value value={ UserDiscriminators.pro }><T>users_form_user_generalInformations_discriminator_pro</T></Input.Radio.Value>
                  <Input.Radio.Value value={ UserDiscriminators.collaborator }><T>users_form_user_generalInformations_discriminator_collaborator</T></Input.Radio.Value>
                </Input.Radio.Btn>
              </Field.Input>
            </Field.Display>
          )}
          </Form.Simple.Adapter>
        </Display.If>
      </Section.Content>
    </Section>
  );
};


const completeToTwoDigits = (value: number) => {
  if(value < 10){
    return `0${value}`;
  }
  return `${value}`;
}
const moneyToString = (value: number) => {
  value *= 100;
  const centimes = value % 100;
  const euros = (value - centimes) / 100;
  return `${euros}€${completeToTwoDigits(centimes)}`;
}

const x100 = (handler: (value: number) => void) => (value: number) => handler(value * 100);

const WorkOrder: FunctionComponent<UserProps> = ({ user: { settings: { nbrDisplayedDays }} }) => (
  <Section>
    <Section.Title>
      <T>users_form_user_workOrder_section_title</T>
    </Section.Title>
    <Section.Content>
      <Form.Simple.Adapter name="valorization">
      {(errors, value, set) => (
        <Field.Display errors={ errors }>
          <Field.Label>
            <T>users_form_user_workOrder_valorization_label</T>
          </Field.Label>
          <Field.Input>
            <Input.Quantity gap={ 0.25 } value={ value / 100 } onChange={ x100(set) } textify={ moneyToString } numericType="decimal" />
          </Field.Input>
        </Field.Display>
      )}
      </Form.Simple.Adapter>
      <Form.Simple.Adapter name="settings.nbrDisplayedDays">
      {(errors, value, set) => (
        <>
          <Field.Display>
            <Field.Label>
              <T>users_form_user_workOrder_nbrDisplayedDays_condition_label</T>
            </Field.Label>
            <Field.Input>
              <Input.Radio.Btn value={ value } onChange={ set } inline>
                <Input.Radio.Value value={ nbrDisplayedDays ? nbrDisplayedDays : 1 }><T>users_form_user_workOrder_nbrDisplayedDays_condition_yes</T></Input.Radio.Value>
                <Input.Radio.Value value={ 0 }><T>users_form_user_workOrder_nbrDisplayedDays_condition_no</T></Input.Radio.Value>
              </Input.Radio.Btn>
            </Field.Input>
          </Field.Display>
          <Display.If condition={ value }>
            <Field.Display errors={ errors }>
              <Field.Label>
                <T>users_form_user_workOrder_nbrDisplayedDays_value_label</T>
              </Field.Label>
              <Field.Input>
                <Input.Quantity value={ value } onChange={ set } />
              </Field.Input>
            </Field.Display>
          </Display.If>
        </>
      )}
      </Form.Simple.Adapter>
    </Section.Content>
  </Section>
);
type AuthentificationProviderProps = {
  authentificationProviders: AuthentificationProviderUsername[];
  clear: () => void;
};

type ProAuthentificationProviderProps = UserProProps & AuthentificationProviderProps;

const ProAuthentificationProvider: FunctionComponent<ProAuthentificationProviderProps> = ({ user, authentificationProviders }) => {  
  const type = authentificationProviders[0].type;
  return (
    <>

      <Form.Simple.Adapter name="type">
      {(errors, value, set) => (
        <Field.Display errors={ errors }>
          <Field.Label>
            <T>users_form_user_authentificationProviders_type_label</T>
          </Field.Label>
          <Field.Input>
            <Input.Radio.Btn value={ value } onChange={ set } inline>
              <Input.Radio.Value value={ "email" }><T>users_form_user_authentificationProviders_type_email</T></Input.Radio.Value>
              <Input.Radio.Value value={ "username" }><T>users_form_user_authentificationProviders_type_username</T></Input.Radio.Value>
              <Input.Radio.Value value={ "none" }><T>users_form_user_authentificationProviders_type_any</T></Input.Radio.Value>
            </Input.Radio.Btn>
          </Field.Input>
        </Field.Display>
      )}
      </Form.Simple.Adapter>
      <Display.If condition={ type !== "none" }>
        <Form.Simple.Adapter name="username">
        {(errors, value, set) => (
          <Field.Display errors={ errors } required>
            <Field.Label>
              <T>{ `users_form_user_authentificationProviders_${type}_label` }</T>
            </Field.Label>
            <Field.Input>
              <Input.Text value={ value } onChange={ set } />
            </Field.Input>
          </Field.Display>
        )}
        </Form.Simple.Adapter>
        <Display.If condition={ isNew(user) && type === "username" }>
          <Form.Simple.ErrorAdapter name="password">
          {(errors) => (
            <Field.Display errors={ errors } required>
              <Field.Label>
                <T>users_form_user_authentificationProviders_password_label</T>
              </Field.Label>
              <Field.Input>
                <Nudge type="info"><T>users_form_user_authentificationProviders_password_info</T></Nudge>
                <div className="bs-users-form-user-authentificationProviders_password_container">
                  <Form.Simple.InputAdapter name="password">
                  {(value, set) => (
                    <Input.Password value={ value } onChange={ set } />
                  )}
                  </Form.Simple.InputAdapter>
                  <Form.Simple.InputAdapter name="passwordCopy">
                  {(value, set) => (
                    <Input.Password value={ value } onChange={ set } />
                  )}
                  </Form.Simple.InputAdapter>
                </div>
              </Field.Input>
            </Field.Display>
          )}
          </Form.Simple.ErrorAdapter>
        </Display.If>
      </Display.If>
    </>
  );
}

type CollaboratorAuthentificationProviderProps = {};

const CollaboratorAuthentificationProvider: FunctionComponent<CollaboratorAuthentificationProviderProps> = ({}) => (
  <Form.Simple.Adapter name="username">
  {(errors, value, set) => (
    <Field.Display errors={ errors } required>
      <Field.Label>
        <T>users_form_user_authentificationProviders_email_label</T>
      </Field.Label>
      <Field.Input>
        <Input.Text value={ value } onChange={ set } />
      </Field.Input>
    </Field.Display>
  )}
  </Form.Simple.Adapter>
);

const AuthentificationProvider: FunctionComponent<UserProps> = ({ user }) => (
  <Section>
    <Section.Title>
      <T>users_form_user_authentificationProvider_section_title</T>
    </Section.Title>
    <Section.Content>
      <Form.Simple.Adapter name="authentificationProviders" multiple>
      {(errors, values, add, drop, set, clear) => {
        return (
        <Form.Simple 
          value={ (values?.length && values[0]) || { discriminator: "username" } }
          onChange={ (form, value) => { values?.length ? set(0, value) : add(value); return value; }}
          default={{ discriminator: "username", username: "" }}
          errors={ Form.Simple.toRelayError(errors, "authentificationProviders[0]") }
          hasDependency
        >
        {() => (
          <>
            { isUserPro(user) && <ProAuthentificationProvider user={ user } authentificationProviders={ values } clear={ clear } /> }
            { isUserCollaborator(user) && <CollaboratorAuthentificationProvider /> }
          </>
        )}
        </Form.Simple>
      )}}
      </Form.Simple.Adapter>
    </Section.Content>
  </Section>
);

const Roles: FunctionComponent<UserProps> = ({ user }) => {
  if(isUserPro(user)){
    return <ProRoles user={ user }/>;
  }
  if(isUserCollaborator(user)){
    return <CollaboratorRoles />;
  }
  throw new Error("Unknown user type");
};

const CollaboratorRoles: FunctionComponent = ({}) => (
  <Section>
    <Section.Title>
      <T>users_form_user_roles_section_title</T>
    </Section.Title>
    <Section.Content>
      <Form.Simple.Adapter name="roles">
      {(errors, value, set) => (
        <Field.Display errors={ errors }>
          <Field.Label>
            <T>users_form_user_roles_section_roles_label</T>
          </Field.Label>
          <Field.Input>
            <Input.Radio.Btn value={ value?.length ? value[0] : null } onChange={ (value: string) => set([value]) } inline>
              <Input.Radio.Value value={ UserCollaboratorRoles.superAdmin }><T>users_form_user_roles_section_roles_superAdmin</T></Input.Radio.Value>
              <Input.Radio.Value value={ UserCollaboratorRoles.appAdmin }><T>users_form_user_roles_section_roles_appAdmin</T></Input.Radio.Value>
              <Input.Radio.Value value={ UserCollaboratorRoles.issueProvider }><T>users_form_user_roles_section_roles_issueProvider</T></Input.Radio.Value>
            </Input.Radio.Btn>
          </Field.Input>
        </Field.Display>
      )}
      </Form.Simple.Adapter>
      <Form.Simple.Adapter name="geographicalAreas">
      {(errors, value, set) => (
        <Field.Display errors={ errors }>
          <Field.Label>
            <T>users_form_user_roles_section_geographicalAreas_label</T>
          </Field.Label>
          <Field.Input>
            <Input.Checkbox.Btn value={ value } onChange={ set } inline>
              <Input.Checkbox.Value value="fr"><T>users_form_user_roles_section_geographicalAreas_france</T></Input.Checkbox.Value>
              <Input.Checkbox.Value value="be"><T>users_form_user_roles_section_geographicalAreas_belgium</T></Input.Checkbox.Value>
              <Input.Checkbox.Value value="lu"><T>users_form_user_roles_section_geographicalAreas_luxembourg</T></Input.Checkbox.Value>
            </Input.Checkbox.Btn>
          </Field.Input>
        </Field.Display>
      )}
      </Form.Simple.Adapter>
    </Section.Content>
  </Section>
);

type TenantRolesFormProps = {
  index: number;
  set: (index: number, tenant: UserProTenant) => void;
  tenantRoles: UserProTenant;
  agentOnTenantEntity: BusinessEntity<Tenant, {}> | null;
  entity: BusinessEntity<Tenant, {}>;
}

const issuersRoles: UserProAclAllowedRoles[] = [
  UserProAclAllowedRoles.publicSpaceIssuer,
  UserProAclAllowedRoles.buildingIssuer,
  UserProAclAllowedRoles.equipmentIssuer
];

const viewersRoles: UserProAclAllowedRoles[] = [
  UserProAclAllowedRoles.publicSpaceViewer,
  UserProAclAllowedRoles.buildingViewer,
  UserProAclAllowedRoles.equipmentViewer
];

const othersRoles: UserProAclAllowedRoles[] = [
  UserProAclAllowedRoles.admin,
  UserProAclAllowedRoles.manager,
  UserProAclAllowedRoles.categoryManager,
  UserProAclAllowedRoles.agent,
  UserProAclAllowedRoles.statistician
];

const filterRoles = (listRoles: UserProAclAllowedRoles[], roles: UserProAclAllowedRoles[]) => {
  return roles.filter(role => !listRoles.includes(role));
}

type RadioButtonProps = {
  pushed: boolean,
  disabled: boolean,
  forbiddenBy?: BusinessEntity<Tenant, {}> | null
  "data-testid": string,
  prefix: string,
  role: string
}

const ButtonPushed   = Button.Stylized.backgroundOrange.borderGray.textWhite.fluid.small;
const ButtonDisabled = Button.Stylized.backgroundOrangeTransparent.borderGray.textBlack.fluid.small.notAllowed;
const ButtonUnpushed = Button.Stylized.backgroundWhite.borderGray.textBlack.fluid.small;
const ButtonForbidden = Button.Stylized.backgroundGray.borderGray.textWhite.fluid.small.notAllowed;

const RoleButton: FunctionComponent<RadioButtonProps> = ({ pushed, "data-testid": dataTestId, disabled, prefix, role, forbiddenBy = null }) => {
  let Button = null;
  if(forbiddenBy) {
    Button = ButtonForbidden;
  } else if(disabled) {
    Button = ButtonDisabled;
  } else if(pushed) {
    Button = ButtonPushed;
  } else {
    Button = ButtonUnpushed;
  }
  return (
    <Tooltip>
      <Tooltip.Subject>
        <Button data-testid={ dataTestId }>
          <T>{ `users_form_user_tenantRolesForm_${prefix}_${role}` }</T>
        </Button>
      </Tooltip.Subject>
      <Tooltip.Info className="bs-users-form-user-radio-tooltip">
        <div className="bs-users-form-user-radio-tooltip-content">
          <div className="bs-users-form-user-radio-tooltip-content-icon">
          { forbiddenBy
              ? <span className="fa fa-exclamation-triangle" />
              : <span className="fa fa-info-circle" />
          }
          </div>
          <div className="bs-users-form-user-radio-tooltip-content-body">
          { forbiddenBy
              ? <T bind={{
                  forbiddenBy: (<Text style={ Style.bold }><T>{ forbiddenBy.name }</T></Text>)
                }}>{`users_form_user_tenantRolesForm_${prefix}_${role}_helper_forbidden`}</T>
              : <T>{`users_form_user_tenantRolesForm_${prefix}_${role}_helper`}</T>
          }            
          </div>
        </div>
      </Tooltip.Info>
    </Tooltip>
  );
}

const RoleCheckbox = Input.Checkbox.withButton(RoleButton);

const LabelConstraint: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className="bs-users-form-user-labelConstraint">
    { children }
  </div>
);

const filterQuery = (property: string) => (value: string) => {
  return {[property]: { $regex: value, $options: "i" }};
}

const buildingTextify = (building: BusinessEntity<Tenant, {}>) => building.name;

const BuildingSelectedItem = ({ building, remove }: { building: Building, remove: (value: Building[]) => void }) => {
  const _delete = React.useCallback(() => remove([building]), [building, remove]);
  return (
    <Selected data={ building } onRemove={ _delete }>
      <Text>{ building.name }</Text>
    </Selected>
  );
};

type Tag = { _id: string };

const TagSelectedItem = ({ tag, remove }: { tag: Tag, remove: (value: Tag[]) => void }) => {
  const _delete = React.useCallback(() => remove([tag]), [tag, remove]);
  return (
    <Selected data={ tag }  onRemove={ _delete }>
      <Text>{ tag._id }</Text>
    </Selected>
  );
};

const TagItem = ({ data }: { data: Tag }) => (
  <ItemTiny>
    <Text>{ data._id }</Text>
  </ItemTiny>
);

const TenantRolesForm: FunctionComponent<TenantRolesFormProps> = ({ index, set, tenantRoles, agentOnTenantEntity, entity }) => {
  const aclService = useService<AclService>("acl");
  const acl = aclService.acl;

  const roleIsImplicitalySelected = React.useCallback((role: UserProAclAllowedRoles) => {
    const oRole = acl.getRole(role) as Role;
    return tenantRoles.roles.some(r => acl.getRole(r).isInheritedFrom(oRole));
  }, [tenantRoles.roles, acl]);

  const updatedRoles = React.useCallback((roles: UserProAclAllowedRoles[]) => {
    const oRoles = _.uniq(roles).map((role: UserProAclAllowedRoles) => acl.getRole(role)) as Role[];

    roles = oRoles
      .filter(role => !oRoles.some(otherRole => role !== otherRole && otherRole.isInheritedFrom(role)))
      .map(role => role.name) as UserProAclAllowedRoles[];

    set(index, { ...tenantRoles, roles });
  }, [index, set, tenantRoles, acl]);

  const updateDisabled = React.useCallback((disabled: boolean) => {
    set(index, { ...tenantRoles, disabled });
  }, [index, set, tenantRoles]);

  const updateTeamApplication = React.useCallback((allowed: boolean) => {
    set(index, { ...tenantRoles, tablet: { allowed } });
  }, [index, set, tenantRoles]);

  const updateOthers = React.useCallback((roles: UserProAclAllowedRoles[]) => {
    updatedRoles([...filterRoles(othersRoles, tenantRoles.roles), ...roles ]);
  }, [updatedRoles, tenantRoles.roles]);

  const others = React.useMemo(() => {
    return tenantRoles.roles.filter(role => othersRoles.includes(role));
  }, [tenantRoles.roles]);

  const updateIssuers = React.useCallback((roles: UserProAclAllowedRoles[]) => {
    updatedRoles([...filterRoles(issuersRoles, tenantRoles.roles), ...roles ]);
  }, [updatedRoles, tenantRoles.roles]);

  const issuers = React.useMemo(() => {
    return tenantRoles.roles.filter(role => issuersRoles.includes(role));
  }, [tenantRoles.roles]);

  const updateViewers = React.useCallback((roles: UserProAclAllowedRoles[]) => {
    updatedRoles([...filterRoles(viewersRoles, tenantRoles.roles), ...roles ]);
  }, [updatedRoles, tenantRoles.roles]);

  const viewers = React.useMemo(() => {
    return tenantRoles.roles.filter(role => viewersRoles.includes(role));
  }, [tenantRoles.roles]);

  const updateBuildingIdLimitation = React.useCallback((ids: { _id: ObjectId }[]) => {
    set(index, { ...tenantRoles, buildings: { ...tenantRoles.buildings, ids: ids.map(o => o._id) } });
  }, [index, set, tenantRoles]);

  const updateBuildingTagLimitation = React.useCallback((tags: Tag[]) => {
    set(index, { ...tenantRoles, buildings: { ...tenantRoles.buildings, tags: tags.map(tag => tag._id) } });
  }, [index, set, tenantRoles]);

  const agentForbiddenBy = (role: UserProAclAllowedRoles) => {
    if(role !== UserProAclAllowedRoles.agent || !agentOnTenantEntity || agentOnTenantEntity._id === tenantRoles.tenant){
      return null;
    }
    return agentOnTenantEntity;
  };

  const isBuildingViewerOrIssuer = React.useMemo(() => {
    return tenantRoles.roles.some(role => [UserProAclAllowedRoles.buildingIssuer, UserProAclAllowedRoles.buildingViewer].includes(role));
  }, [tenantRoles.roles]);

  const api = useService<ApiService>("api");

  const tagsSource = React.useCallback((query: object, sort: object, offset: number, pageSize: number) => 
    api.service("issues", "tags")
      .execute(Query.joinWithOptimizer(query, { tenant: tenantRoles.tenant }), sort, offset, pageSize)
  , [api, tenantRoles.tenant]);

  const allowOthersRole = (role: UserProAclAllowedRoles) =>
    role === UserProAclAllowedRoles.manager || entity.settings.commercialOffer !== "starter";
  
  const allowIssuerRole = (role: UserProAclAllowedRoles) => 
    !((entity.settings.commercialOffer === "expert" && !entity.settings.allowPublicSpaceIssue  && role === UserProAclAllowedRoles.publicSpaceIssuer)
    || (entity.settings.commercialOffer !== "expert" && role !== UserProAclAllowedRoles.publicSpaceIssuer));

  const allowViewerRole = (role: UserProAclAllowedRoles) =>
    !((entity.settings.commercialOffer === "expert" && !entity.settings.allowPublicSpaceIssue  && role === UserProAclAllowedRoles.publicSpaceViewer)
    || (entity.settings.commercialOffer !== "expert" && role !== UserProAclAllowedRoles.publicSpaceViewer));

  
  return (
    <>
      <Field.Table >
        <Field.Display>
          <Field.Label>
            <LabelConstraint>
              <T>users_form_user_tenantRolesForm_disabled_label</T>
            </LabelConstraint>
          </Field.Label>
          <Field.Input>
            <Input.Radio.Btn value={ tenantRoles.disabled } onChange={ updateDisabled } inline>
              <Input.Radio.Value value={ false }><T>users_form_user_tenantRolesForm_disabled_false</T></Input.Radio.Value>
              <Input.Radio.Value value={ true }><T>users_form_user_tenantRolesForm_disabled_true</T></Input.Radio.Value>
            </Input.Radio.Btn>
          </Field.Input>
        </Field.Display>
        <Field.Display>
          <Field.Label>
            <LabelConstraint>
              <T>users_form_user_tenantRolesForm_othersRoles_label</T>
            </LabelConstraint>
          </Field.Label>
          <Field.Input>
            <RoleCheckbox value={ others } onChange={ updateOthers } inline>
            {
              othersRoles.filter(allowOthersRole).map(role => (
                <Input.Checkbox.Value 
                  key={ role }
                  value={ role }
                  disabled={ roleIsImplicitalySelected(role) || !!agentForbiddenBy(role) }
                  forbiddenBy={ agentForbiddenBy(role) }
                  role={role}
                  prefix="othersRoles"
                />
              ))
            }
            </RoleCheckbox>
          </Field.Input>
        </Field.Display>
        { agentOnTenantEntity?._id === tenantRoles.tenant && (    
          <Field.Display>
            <Field.Label>
              <LabelConstraint>
                <T>users_form_user_tenantRolesForm_teamApplication_label</T>
              </LabelConstraint>
            </Field.Label>
            <Field.Input>
              <Input.Radio.Btn value={ tenantRoles.tablet.allowed } onChange={ updateTeamApplication } inline>
                <Input.Radio.Value value={ true }><T>users_form_user_tenantRolesForm_teamApplication_true</T></Input.Radio.Value>
                <Input.Radio.Value value={ false }><T>users_form_user_tenantRolesForm_teamApplication_false</T></Input.Radio.Value>
              </Input.Radio.Btn>
            </Field.Input>
          </Field.Display>
        )}
        <Display.If condition={ entity.settings.commercialOffer !== "starter" }>
          <Field.Display>
            <Field.Label>
              <LabelConstraint>
                <T>users_form_user_tenantRolesForm_issuersRoles_label</T>
              </LabelConstraint>
            </Field.Label>
            <Field.Input>
              <RoleCheckbox value={ issuers } onChange={ updateIssuers } inline>
              {
                issuersRoles.filter(allowIssuerRole).map(role => (
                  <Input.Checkbox.Value 
                    key={ role }
                    value={ role }
                    disabled={ roleIsImplicitalySelected(role) }
                    role={role}
                    prefix="issuersRoles"
                  />
                ))
              }
              </RoleCheckbox>
            </Field.Input>
          </Field.Display>
          <Field.Display>
            <Field.Label>
              <LabelConstraint>
                <T>users_form_user_tenantRolesForm_viewersRoles_label</T>
              </LabelConstraint>
            </Field.Label>
            <Field.Input>
              <RoleCheckbox value={ viewers } onChange={ updateViewers } inline>
              {
                viewersRoles.filter(allowViewerRole).map(role => ( 
                  <Input.Checkbox.Value 
                    key={ role }
                    value={ role }
                    disabled={ roleIsImplicitalySelected(role) }
                    role={role}
                    prefix="viewersRoles"
                  />
                ))
              }
              </RoleCheckbox>
            </Field.Input>
          </Field.Display>
        </Display.If>
        {
          isBuildingViewerOrIssuer && (
            <Field.Display>
              <Field.Label>
                <LabelConstraint>
                  <T>users_form_user_tenantRolesForm_buildingLimitation_label</T>
                </LabelConstraint>
              </Field.Label>
              <Field.Input>
                <Field.Untable>
                  <Field.Display>
                    <Field.Label>
                      <LabelConstraint>
                        <T>users_form_user_tenantRolesForm_buildingLimitation_byId_label</T>
                      </LabelConstraint>
                    </Field.Label>
                    <Field.Input>
                      <Input.Selectable
                        filterQuery={ filterQuery("name") }
                        query={{ tenant: tenantRoles.tenant, disabled: false, }}
                        sort={{ name: -1 } }
                        load={ BuildingItem.Load }
                        model="Building"
                        value={ tenantRoles.buildings.ids.map(_id => ({ _id })) }
                        onChange={ updateBuildingIdLimitation }
                        textify={ buildingTextify }
                        inline
                      >
                        <Input.Selectable.Item>
                          <BuildingItem />
                        </Input.Selectable.Item>
                        <Input.Selectable.SelectedItem>
                        {(value: Building, _delete: (values: Building[]) => void, key: string) => (
                          <BuildingSelectedItem building={ value } remove={ _delete } key={ key } />
                        )}
                        </Input.Selectable.SelectedItem>
                      </Input.Selectable>
                    </Field.Input>
                  </Field.Display>
                  <Field.Display>
                    <Field.Label>
                      <LabelConstraint>
                        <T>users_form_user_tenantRolesForm_buildingLimitation_byTag_label</T>
                      </LabelConstraint>
                    </Field.Label>
                    <Field.Input>
                    <Input.Selectable
                      source={ tagsSource }
                      query={{ origin: "building" }}
                      sort={{ name: 1 }}
                      pageSize={ 25 }
                      textify={ (tag: Tag) => tag._id }
                      filterQuery={ filterQuery("_id") }
                      value={ tenantRoles.buildings.tags.map(_id => ({ _id, name: _id })) }
                      onChange={ updateBuildingTagLimitation }
                      inline
                    >
                      <Input.Selectable.SelectableItem>
                       <TagItem />
                      </Input.Selectable.SelectableItem>
                      <Input.Selectable.SelectedItem>
                      {(value: Tag, _delete: (values: Tag[]) => void, key: string) => (
                        <TagSelectedItem tag={ value } remove={ _delete } key={ key } />
                      )}
                      </Input.Selectable.SelectedItem>
                    </Input.Selectable>
                    </Field.Input>
                  </Field.Display>
                </Field.Untable>
              </Field.Input>
            </Field.Display>
          )
        }
      </Field.Table>
    </>
  );
};


type SelectTenantProps = {
  tenantsAlreadSelected: ObjectId<"Tenant">[];
  add: (tenant: ObjectId<"Tenant">) => void;
  close: () => void;
}

const getTenantQuery = (session: SessionService, withoutTenant: ObjectId<"Tenant">[] = []) => {
  let query = {
    "settings.commercialOffer": { $ne: null }
  };
  if(session.user.discriminator === "pro"){
    query = combinate("$and", query, {
      _id: { $in: session.user.tenants.map(t => t.tenant) }
    });
  } else if(session.user.grographicalAreas?.length){
    query = combinate("$and", query, {
      country: { $in: session.user.grographicalAreas }
    });
  }

  if(withoutTenant.length){
    query = combinate("$and", query, {
      _id: { $nin: withoutTenant }
    });
  }

  return query;

}

const SelectTenant: FunctionComponent<SelectTenantProps> = ({ tenantsAlreadSelected, add, close }) => {
  const i18n = useService<I18nService>("i18n");
  const ref = React.createRef<SelectableList>();
  const session = useService<SessionService>("session");
  
  const query = React.useMemo(() => {
    return getTenantQuery(session, tenantsAlreadSelected);
  }, [JSON.stringify(tenantsAlreadSelected.sort()), session]);

  const addTenant = React.useCallback(() => {
    if(!ref.current) {
      return;
    }
    const seletectedTenants = ref.current.selectedValues as BusinessEntity<Tenant, {}>[];
    if(!seletectedTenants.length){
      return;
    }
    const tenant = seletectedTenants[0];
    add(tenant._id);
    close();
  }, [ref, add, close]);

  return (
    <Modal.Show close={ close } style={{ height: "80vh", width: "35vw" }}>
    {(close) => (
      <ModalTemplate>
        <ModalTemplate.Content>
          <SelectableList
            ref={ ref }
            filterQuery={ filterQuery(i18n.queryProperty("name")) }
            model="Tenant"
            query={ query }
            sort={ { [i18n.queryProperty("name")]: 1 }} 
            load={ TenantItem.Load }
            limit={ 1 }
          >
            <SelectableList.Item>
              <TenantItem />
            </SelectableList.Item>
          </SelectableList>
        </ModalTemplate.Content>
        <ModalTemplate.ActionRight>
          <ValidateButton onClick={ addTenant }>
            <T>users_form_user_selectTenant_validate_button</T>
          </ValidateButton>
        </ModalTemplate.ActionRight>
      </ModalTemplate>
    )}
    </Modal.Show>
  );
};

type ValidateTenantDropDatas = {
  tenant: BusinessEntity<Tenant>;
  user: UserPro;
}

const styleTextValidateTenantDrop = Style.darkGray.center;
const styleTextBoldValidateTenantDrop = styleTextValidateTenantDrop.bold;

const ValidateTenantDrop: FunctionComponent<QuestionProps<ValidateTenantDropDatas>> = ({ data: { tenant, user }, accept, reject, error }) => (
  <Modal.Show style={{ width: "320px" }}>
    <div className="bs-users-form-user-validateTenantDrop">
      <Text.Paragraph style={ styleTextValidateTenantDrop }>
        <T bind={{ 
          tenant: (<Text style={ styleTextBoldValidateTenantDrop }><T>{ tenant.name }</T></Text>),
          user: (<Text style={ styleTextBoldValidateTenantDrop }>{ user.fullname }</Text>)
        }}>users_form_user_validateTenantDrop_label</T>
      </Text.Paragraph>
      <div className="bs-users-form-user-validateTenantDrop-actions">
        <ValidateButton onClick={ accept }>
          <T>users_form_user_validateTenantDrop_validate</T>
        </ValidateButton>
        <CancelButton onClick={ reject }>
          <T>users_form_user_validateTenantDrop_cancel</T>
        </CancelButton>
      </div>
    </div>
  </Modal.Show>
);

type TenantsRolesProps = {
  user: UserPro;
  tenants: UserProTenant[];
  add: (tenant: UserProTenant) => void;
  drop: (index: number) => void;
  set: (index: number, tenant: UserProTenant) => void;
}

const tabHeaderSelectedStyle = Style.darkGray.bold;
const tabHeaderUnselectedStyle = Style.darkGray;

const DropButton = withMouseEventStopped(({ onClick }) => (
  <div className="bs-users-form-user-tenantRoles-tabHeader-remove">
    <span className="fa fa-times" onClick={ onClick } />
  </div>
));

const TenantsRoles: FunctionComponent<TenantsRolesProps> = ({ user, tenants, set, add, drop }) => {
  const [selectTenant, openSelectTenant, closeSelectTenant] = useOpenCloseToggle();
  const tenantRepository = useRepository<Tenant, "Tenant">("Tenant");
  const [tenantEntities, setTenantEntities] = React.useState<BusinessEntity<Tenant, {}>[]>([]);

  const acl = useService<AclService>("acl");
  tenants = tenants
    .map((tenant, index) => ({ ...tenant, index }))
    .filter(tenant => acl.connectedUserIsAllow("users", "manage", tenant.tenant as ObjectId<"Tenant">));

  const [{ deferSelect }, setControl] = useTabControl(tenantEntities.map(tenant => tenant._id));

  const currentTenant = useService<CurrentTenantService>("currentTenant");
  React.useEffect(() => {
    if(currentTenant.isSelected()){
      deferSelect(currentTenant.currentId as string);
    }
  }, []);

  const addAndSelect = React.useCallback((tenant: ObjectId<"Tenant">) => {
    add({
      tenant,
      roles: [],
      buildings: { ids: [], tags: [] },
      tablet: {
        allowed: true
      },
      canValorizeForOthers: false,
      disabled: false
    });
    deferSelect(tenant);
  }, [add]);

  const [ValidateTenantDropControlled, doesUserConfirmDrop] = useAsyncValidationWithQuestion(ValidateTenantDrop)

  const confirmDrop = React.useCallback(async (tenant: BusinessEntity<Tenant>, user: UserPro, index: number) => {
    if(await doesUserConfirmDrop({ tenant, user })){
      drop(index);
    }
  }, []);


  const hashTenants = JSON.stringify(tenants.toSorted((t1, t2) => t1.tenant.localeCompare(t2.tenant)).map(tenant => tenant.tenant));

  const manageOthersTenants = React.useMemo<boolean>(() => {
    if(currentTenant.changeMode === "unlimited"){
      return true;
    }
    return currentTenant.allTenants.filter(tenant => !tenants.some(t => tenant._id === t.tenant)).length > 1;
  }, [currentTenant, JSON.stringify(tenants.map(t => t.tenant))]);

  React.useEffect(() => {    
    tenantRepository
      .get(tenants.map(tenant => ({ _id: tenant.tenant as ObjectId<"Tenant"> })))
      .then(setTenantEntities);
    
  }, [tenantRepository, hashTenants]);

  const i18n = useService<I18nService>("i18n");

  const agentOnTenant = tenants.find(t => t.roles.includes(UserProAclAllowedRoles.agent));
  let agentOnTenantEntity = null;
  if(agentOnTenant){
    agentOnTenantEntity = tenantEntities.find(tenant => tenant._id === agentOnTenant.tenant) || null;
  }

  const preparedRoles = tenants
    .filter(t => tenantEntities.some(tenant => tenant._id === t.tenant))
    .map(t => {
      const entity = tenantEntities.find(tenant => tenant._id === t.tenant);
      return {
        ...t,
        entity
      };
    }).sort((t1, t2) => {
      return (i18n.translateObject(t1.entity.name) as string).localeCompare(i18n.translateObject(t2.entity.name) as string);
    }) as (UserProTenant & { 
      entity: BusinessEntity<Tenant, {}>,
      index: number
    })[];

  return (
    <>
      <TabList add={ manageOthersTenants && openSelectTenant } setControl={ setControl }>
      {
        preparedRoles.map(({ entity, index, ...tenant }) => (
          <TabList.Tab key={ entity._id } id={ entity._id }>
            <TabList.Header>
            {({ active }) => (
              <div className="bs-users-form-user-tenantRoles-tabHeader">
                <div className="bs-users-form-user-tenantRoles-tabHeader-label">
                  <Text style={ active ? tabHeaderSelectedStyle : tabHeaderUnselectedStyle }>
                    <T>{ entity.name }</T>
                  </Text>
                </div>
                <DropButton onClick={ () => confirmDrop(entity, user, index) } />
              </div>
            )}
            </TabList.Header>
            <TabList.Content>
              <TenantRolesForm index={ index } set={ set } tenantRoles={ tenant } agentOnTenantEntity={ agentOnTenantEntity } entity={ entity }/>
            </TabList.Content>
          </TabList.Tab>
        ))
      }
      </TabList>
      <Display.If condition={ selectTenant }>
        <SelectTenant 
          tenantsAlreadSelected={ tenants.map(t => t.tenant as ObjectId<"Tenant">) }
          add={ addAndSelect }
          close={ closeSelectTenant }
        />
      </Display.If>
      { ValidateTenantDropControlled }
    </>
  );
}
type NoEmailTenantRoleProps = {
  withTablet: boolean;
}

const tenantTextify = (tenant: Tenant) => <T>{ tenant.name }</T>;



const TenantSelectedItem = ({ tenant, remove }: { tenant: Tenant, remove: (value: Tenant[]) => void }) => {
  const _delete = React.useCallback(() => remove([tenant]), [tenant, remove]);
  return (
    <Selected data={ tenant }  onRemove={ _delete }>
      <Text><T>{ tenant.name }</T></Text>
    </Selected>
  );
};


const NoEmailTenantRole: FunctionComponent<NoEmailTenantRoleProps> = ({ withTablet }) => {
  const i18n = useService<I18nService>("i18n");
  const session = useService<SessionService>("session");
  
  const query = React.useMemo(() => {
    return getTenantQuery(session);
  }, [session]);
  return (
    <>
      <Form.Simple.Adapter name="tenants.0.tenant">
      {(errors, value, set) => (
        <Field.Display>
          <Field.Label>
            <T>users_form_user_noEmailTenantRole_tenant_label</T>
          </Field.Label>
          <Field.Input>
            <Input.Selectable
              filterQuery={ filterQuery(i18n.queryProperty("name")) }
              query={query}
              sort={{ [i18n.queryProperty("name")]: 1 } }
              load={ TenantItem.Load }
              model="Tenant"
              limit={ 1 }
              value={ value ? [{ _id: value }] : [] }
              onChange={ (values: Tenant[]) => values.length ? set(values[0]._id) : set(null) }
              textify={ tenantTextify }
              inline
            >
              <Input.Selectable.SelectableItem>
                <TenantItem />
              </Input.Selectable.SelectableItem>
              <Input.Selectable.SelectedItem>
              {(value: Tenant, _delete: (values: Tenant[]) => void, key: string) => (
                <TenantSelectedItem tenant={ value } remove={ _delete } key={ key } />
              )}
              </Input.Selectable.SelectedItem>
            </Input.Selectable>
          </Field.Input>
        </Field.Display>
      )}
      </Form.Simple.Adapter>
      <Field.Display>
        <Field.Label>
          <T>users_form_user_noEmailTenantRole_roles_label</T>
        </Field.Label>
        <Field.Input>
          <T>users_form_user_noEmailTenantRole_roles_agent</T>
        </Field.Input>
      </Field.Display>
        <Form.Simple.Adapter name="tenants.0.disabled">
        {(errors, value, set) => (
          <Field.Display errors={ errors }>
            <Field.Label>
              <T>users_form_user_noEmailTenantRole_disabled_label</T>
            </Field.Label>
            <Field.Input>
              <Input.Radio.Btn value={ value } onChange={ set } inline>
                <Input.Radio.Value value={ false }><T>users_form_user_noEmailTenantRole_disabled_false</T></Input.Radio.Value>
                <Input.Radio.Value value={ true }><T>users_form_user_noEmailTenantRole_disabled_true</T></Input.Radio.Value>
              </Input.Radio.Btn>
            </Field.Input>
          </Field.Display>
        )}
        </Form.Simple.Adapter>
      <Display.If condition={ withTablet }>
        <Form.Simple.Adapter name="tenants.0.tablet.allowed">
        {(errors, value, set) => (
          <Field.Display errors={ errors }>
            <Field.Label>
              <T>users_form_user_noEmailTenantRole_teamApplication_label</T>
            </Field.Label>
            <Field.Input>
              <Input.Radio.Btn value={ value } onChange={ set } inline>
                <Input.Radio.Value value={ true }><T>users_form_user_noEmailTenantRole_teamApplication_true</T></Input.Radio.Value>
                <Input.Radio.Value value={ false }><T>users_form_user_noEmailTenantRole_teamApplication_false</T></Input.Radio.Value>
              </Input.Radio.Btn>
            </Field.Input>
          </Field.Display>
        )}
        </Form.Simple.Adapter>
      </Display.If>
    </>
  );
}

const ProRoles: FunctionComponent<UserProProps> = ({ user }) => {
  const withTablet = user.authentificationProviders[0].type !== "none";
  const useMultipleTenants = user.authentificationProviders[0].type === "email";
  return (
    <Section>
      <Section.Title>
        <T>users_form_user_roles_section_title</T>
      </Section.Title>
      <Section.Content>
        <Display.If condition={ useMultipleTenants }>
          <Display.Then>
            <Form.Simple.Adapter name="tenants" multiple>
            {(errors, values, add, drop, set, clear) => (
              <TenantsRoles user={ user } tenants={ values || [] } add={ add } drop={ drop } set={ set } />
            )}
            </Form.Simple.Adapter>
          </Display.Then>
          <Display.Else>
            <NoEmailTenantRole withTablet={ withTablet }/>
          </Display.Else>
        </Display.If>
      </Section.Content>
    </Section>
  );
}

const Datas: FunctionComponent<UserProps> = ({ user }) => {
  return (
    <div>
      <Layout.Columns>
        <Layout.Columns.Column>
          <Marger>
            <GeneralInformations user={ user } />
          </Marger>
        </Layout.Columns.Column>
        {
          isAgent(user) &&
          <Layout.Columns.Column>
            <Marger>
              <WorkOrder user={ user }/>
            </Marger>
          </Layout.Columns.Column>
        }
        </Layout.Columns>
      <Display.If condition={ user.discriminator }>
        <Marger>
          <AuthentificationProvider user={ user }/>
        </Marger>
        <Marger>
          <Roles user={ user }/>
        </Marger>
      </Display.If>
    </div>
  );
}


type FormSimple = typeof Form.Simple;

export const stdOnChange: (currentTenant: CurrentTenantService) => 
  ((form: FormSimple, value: (UserPro | UserCollaborator) & AuthType, diff: object[]) => (UserPro | UserCollaborator) & AuthType) = 
(currentTenant: CurrentTenantService) => {
  return (form, value, diff) => {
    const authentificationProviderTypeChanged = diff.some(d => d.path.join(".") === "authentificationProviders.0.type");
    const discriminatorChanged = diff.some(d => d.path.join(".") === "discriminator");
    if(isUserPro(value)) {
      if(discriminatorChanged){
        value.tenants = [{
          tenant: currentTenant.currentId as ObjectId<"Tenant">,
          roles: [UserProAclAllowedRoles.agent],
          buildings: { ids: [], tags: [] },
          canValorizeForOthers: false,
          tablet: {
            allowed: true
          },
          disabled: false
        }];
      }
      if(authentificationProviderTypeChanged){
        if(["none", "username"].includes(value.authentificationProviders[0].type)){
          value.tenants = [{
            tenant: currentTenant.currentId as ObjectId<"Tenant">,
            roles: [UserProAclAllowedRoles.agent],
            buildings: { ids: [], tags: [] },
            canValorizeForOthers: false,
            tablet: {
              allowed: true
            },
            disabled: false
          }];
        } else {
          value.tenants = [{
            tenant: currentTenant.currentId as ObjectId<"Tenant">,
            roles: [],
            buildings: { ids: [], tags: [] },
            canValorizeForOthers: false,
            tablet: {
              allowed: true
            },
            disabled: false
          }];
        }
      }
    } else if(isUserCollaborator(value)) {
      if(discriminatorChanged){
        value.disabled = false;
        value.roles = [];
        value.geographicalAreas = [];
        value.authentificationProviders[0].type = "email";
      }
    }
    return value;
  };
}

export const stdSubmit = (apiService: ApiService, close: () => void) => {
  return async (form: FormSimple, user: (UserPro | UserCollaborator) & AuthType) => {
    user.authentificationProviders = user.authentificationProviders.filter((provider) => provider.type !== "none");
    if(user.authentificationProviders.length){
      if (user.authentificationProviders[0].type === "email") {
        user.email = user.authentificationProviders[0].username;
      }
      user.authentificationProviders[0].discriminator = "username";
      delete user.authentificationProviders[0].type;
    }
    if(isNew(user)){
      await apiService.service("users", "post").execute(user);
    } else {
      await apiService.service("users", "put").execute(user._id, user);
    }
    close();
  };
}

export const getValidator = (repository: RepositoryService, i18n: I18nService) => {

  const authentificationProviders = { type: "array", min: 1, items: { type: "discriminator", rules: [{
      test: (value: any) => value.type === "none",
      rule: { type: "object", props: {
        type: { type: "string", required: true, enum: ["none"] },
      }}
    }, {
      test: (value: any) => value.type === "username",
      rule: { type: "object", props: {
        type: { type: "string", required: true, enum: ["username"] },
        username: { type: "string", min: 5, required: true },
        password: { type: "string", pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/, optional: true, empty: false },
      }}
    }, {
      test: (value: any) => value.type === "email",
      rule: { type: "object", props: {
        type: { type: "string", required: true, enum: ["email"] },
        username: { type: "email", required: true },
      }}
    }
  ]}};

  const base = {
    firstname: { type: "string", required: true, empty: false },
    lastname: { type: "string", required: true, empty: false },
    authentificationProviders
  };

  const userPro = {
    ...base,
    discriminator: { type: "string", required: true, enum: ["pro"] },
    settings: { type: "object", props: {
      nbrDisplayedDays: { type: "number", required: true },
    }},
    valorization: { type: "number", required: true },
    tenants: { type: "array", items: {
      type: "object", props: {
        tenant: { type: "string", required: true },
        roles: { type: "array", items: { type: "string" }, required: true },
        buildings: { type: "object", props: {
          ids: { type: "array", items: { type: "string" } },
          tags: { type: "array", items: { type: "string" } }
        }},
        tablet: { type: "object", props: {
          allowed: { type: "boolean", required: true }
        }},
        disabled: { type: "boolean", required: true }
      }
    }},
  }

  const collaborator = {
    ...base,
    discriminator: { type: "string", required: true, enum: ["collaborator"] },
    disabled: { type: "boolean", required: true },
    roles: { type: "array", items: { type: "string" }, required: true },
    geographicalAreas: { type: "array", items: { type: "string" }, required: true }
  }

  const validator = buildValidator([
    { premise: isUserPro, schema: userPro },
    { premise: isUserCollaborator, schema: collaborator }
  ]);

  validator.rules.unshift((value: any) => {
    if (value.authentificationProviders[0].type === "username" && value.authentificationProviders[0].password !== value.authentificationProviders[0].passwordCopy) {
      return { error: "password_mismatch" };
    }
    return null;
  });

  validator.rules.unshift(async value => {
    const tenantsWithoutRoles = value.tenants.filter((t: UserProTenant) => t.tenant !== null && !t.roles.length);
    if (!tenantsWithoutRoles.length) {
      return null;
    }

    const tenants = await repository.get("Tenant").repository.get(tenantsWithoutRoles.map((t: UserProTenant) => ({ _id: t.tenant })));
    const tenantNames = tenants.map((t: Tenant) => i18n.translateObject(t.name)).join(", ");

    return { error: i18n.translateString("users_form_user_error_no_role_on_tenants", { tenantNames }, true) };
  });

  return validator;
}

const UserForm: FunctionComponent<UserProps> = ({ user }) => (
  <Input.File.DropArea>
    <Layout.Columns>
      <Layout.Columns.Column className="bs-users-form-column-avatar">
        <Marger>
          <Avatar />
        </Marger>
      </Layout.Columns.Column>
      <Layout.Columns.Column className="bs-users-form-column-datas">
        <Datas user={ user } />
      </Layout.Columns.Column>
    </Layout.Columns>
  </Input.File.DropArea>
);

export default UserForm;