import React         from "react";
import T             from "@cBehaviour/i18n";
import Filter        from "@cComponents/filter";
import Input         from "@cComponents/input";
import Collapsable   from "@cComponents/collapsable";
import User          from "@entities/user";
import Application   from "@uBehaviour/application";
import useService from "@universal/behaviour/hooks/useService";

const types = [
  { label: "public_space", value: "publicSpace" },
  { label: "building", value: "building" },
  { label: "equipment", value: "equipment" }
];

export default Application.forward(["repository", "currentTenant"], [], (props) => {
  const acl = useService('acl');
  const usedTypes = types.filter(type => type.value === "publicSpace" || acl.connectedUserIsAllow(type.value + "s", "manage"));
  return (
    <>
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>active_m</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="disabled" buildQuery={values => ({ "disabled": { $in: values } })} stringify={value => (<T>{!value ? "active_m" : "inactive_m"}</T>)}>
            {(values, add, drop, clear) => (
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                <Input.Checkbox.Value value={false}><T>yes</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value={true}><T>no</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            )}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>category_filters_default_valorization</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="issuesValorizationMandatoryByDefault" buildQuery={values => ({ "issuesValorizationMandatoryByDefault": { $in: values } })} stringify={value => (<T>{!value ? "category_filters_default_valorization_yes" : "category_filters_default_valorization_no"}</T>)}>
            {(values, add, drop, clear) => (
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                <Input.Checkbox.Value value={true} data-testid="category_filters_issuesValorizationMandatoryByDefault_yes"><T>yes</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value={false} data-testid="category_filters_issuesValorizationMandatoryByDefault_no"><T>no</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            )}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
      {
        usedTypes.length > 1 &&
          <Collapsable alwaysOpen>
            <Collapsable.Title><T>type</T></Collapsable.Title>
            <Collapsable.Content>
              <Filter.Generic multiple name="type" buildQuery={values => ({ "type": { $in: values.map(v => v.value) } })} stringify={value => (<><T>type</T>: <T>{value.label}</T></>)}>
                {(values, add, drop, clear) => (
                  <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
                    {
                      usedTypes.map(type => (<Input.Checkbox.Value key={type.value} value={type}><T>{type.label}</T></Input.Checkbox.Value>))
                    }
                  </Input.Checkbox.BtnFilter>
                )}
              </Filter.Generic>
            </Collapsable.Content>
          </Collapsable>
      }
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>category_filter_citizen_visibility</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="citizen" buildQuery={values => ({ "citizen": { $in: values } })} stringify={value => (<T>{!value ? "category_filter_citizen_visibility_visible" : "category_filter_citizen_visibility_invisible"}</T>)}>
            {(values, add, drop, clear) => (
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                <Input.Checkbox.Value value={true}><T>yes</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value={false}><T>no</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            )}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>category_filter_manager</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="manager" buildQuery={values => ({ "redirectRule.manager": { $in: values.map(v => v._id) } })} stringify={value => { return (<><T>category_filter_manager</T> : {value.fullname}</>)}}>
            {(values, add, drop, clear) => {
              let query = { tenants: { $elemMatch: { tenant: props.currentTenant.currentId, roles: { $in: ["admin", "manager", "categoryManager"]}}}};
              if(values.length){
                query = { $and: [query, { _id: { $nin: values.map(v => v._id) }}]};
              }
              return (
              <Input.Selectable 
                value={ values.map(v => props.repository.get("User").key.extract(v))}
                onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
                model={ props.repository.get("User") } load={{ avatar: true }}
                query={ query }
                textify={ user => user.fullname }
                filterQuery={ value => ({ fullname: { '$regex': value, '$options': 'i' } }) }
              >
                <User.Item />
              </Input.Selectable>
            )}}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>category_filter_follower</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="follower" buildQuery={values => ({ "followers": { $in: values.map(v => v._id) } })} stringify={value => { return (<><T>category_filter_follower</T> : {value.fullname}</>)}}>
            {(values, add, drop, clear) => {
              let query = { "tenants.tenant": props.currentTenant.currentId, "authentificationProviders.0": { $exists: true }};
              if(values.length){
                query = { $and: [query, { _id: { $nin: values.map(v => v._id) }}]};
              }
              return (
              <Input.Selectable 
                value={ values.map(v => props.repository.get("User").key.extract(v))}
                onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
                model={ props.repository.get("User") }
                load={{ avatar: true }}
                query={ query }
                textify={ user => user.fullname }
                filterQuery={ value => ({ fullname: { '$regex': value, '$options': 'i' } }) }
              >
                <User.Item />
              </Input.Selectable>
            )}}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
    </>
  );
});

export { types };