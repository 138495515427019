/* eslint no-unused-expressions: 0 */
import React from "react";
import moment from "moment";
import T  from "@uBehaviour/i18n";
import Location from "@uLib/location";
import UserLib from "@uBusiness/format/user";
import File from "@cComponents/file";
import Display from "@uComponents/displayIf";
import State from "./state";
import Button from "@cComponents/button";
import SchedulerRule from './schedulerRule.tsx';
import "./item.css";

const EndRule = ({ endRule }) => {
  const bind = {};
  switch(endRule.discriminator){
    case "date": bind.date = moment(endRule.date).format("DD/MM/YYYY"); break;
    case "occurenceNumbers": bind.number = endRule.number; break;
  }
  return (
    <div><T bind={bind}>{"recurrence_endRule_" + endRule.discriminator}</T></div>
  );
}
const Item = ({ recurrence, copy }) => (
  <div className="bs-item bs-item-recurrence">
    <div className="bs-item-recurrence-image">
      <Display.If condition={ recurrence.template.files.length }>
        <Display.Then>
          <File file={ recurrence.template.files[0] } width={180} height={180} fit="cover"/>
        </Display.Then>
        <Display.Else>
          <File file={ null }/>
        </Display.Else>
      </Display.If>
    </div>
    <div className="bs-item-recurrence-content">
      <div className="bs-item-recurrence-content-main">
        <div className="bs-item-recurrence-content-main-header">
          <div>
            <span><b><T>{ recurrence.template.category.label }</T></b></span>
            <span>&nbsp;-&nbsp;</span>
            <span>{ recurrence.template.description }</span>
          </div>
          <div className="bs-item-recurrence-address">
            <span className={ `fa ${recurrence.template.location?.building ? "fa-building-o" : recurrence.template.equipment ? "fa-truck" : "fa-map-marker"}`} />
            <span>{ recurrence.template.location?.address || recurrence.template.location?.building ? Location.getLabelFromObject(recurrence.template.location) : recurrence.template.equipment ? " " + recurrence.template.equipment.name : ""}</span>
          </div>
        </div>
        <div className="bs-item-recurrence-content-main-body">
          <SchedulerRule schedulerRule={ recurrence.schedulerRule } eventHorizonInDay={ recurrence.eventHorizonInDay }/>
          <Display.If condition={!recurrence.ended && !recurrence.deleted && recurrence.endRule}>
          {() => (<EndRule endRule={ recurrence.endRule } />)}
          </Display.If>
        </div>
        <div className="bs-item-recurrence-content-main-footer">
          <div className="bs-item-recurrence-content-main-footer-meta">
            <span><T bind={{
              date: moment(recurrence.createdAt).fromNow(),
              creator: UserLib.shortFullname(recurrence.createdBy)
            }}>recurrence_created_at</T></span>
          </div>
        </div>
      </div>
    </div>
    <div className="bs-item-recurrence-meta">
        <State recurrence={ recurrence }/>
        <Button.Text onClick={copy}><T>recurrence_copy</T></Button.Text>
        <Display.If condition={!recurrence.ended && !recurrence.deleted}>
          <div className="bs-item-recurrence-meta-nextExecution"><T bind={{ date: moment(recurrence.nextExecution).format("DD/MM/YYYY")}}>recurrence_next_execution</T></div>
        </Display.If>
    </div>
  </div>
);

export default Item;