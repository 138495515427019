import React                 from "react";
import moment                from "moment";
import md5                   from "md5";
import _                     from "lodash";
import { Grid }              from "semantic-ui-react";
import Filter                from "@cComponents/filter";
import ScrollBar             from "@cComponents/scrollBar";
import Application           from "@uBehaviour/application";
import Data                  from "@uBehaviour/data";
import T                     from "@cBehaviour/i18n";
import Scrollbar             from "@cComponents/scrollBar";
import Display               from "@uComponents/displayIf";
import Filters               from "./filters";
import Frame                 from "./frame";
import Number                from "./number";
import Percentage            from "./percentage";
import Pie                   from "./pie";
import HorizontalBar         from "./horizontalBar";
import NewIssueInTimeBar     from "./newIssueInTimeBar";
import ValorizationBar       from "./valorizationBar";
import FFilter               from '@cFeatures/filter';


import "./index.css";

const stateColors = {
  open: "#f77448",
  planned: "#5dc9e6",
  in_progress: "#6b98b5",
  resolved: "#89cc1f",
  transferred: "#e78fa5",
  rejected: "#aaaa5a",
  deleted: "#a09696",
  merged: "#e01a6c"
};
const valorizationsColors = {
  team: "hsl(28, 100%, 53%)",
  equipment: "hsl(205, 71%, 41%)",
  agent: "hsl(36, 100%, 50%)",
  supply: "hsl(120, 57%, 40%)",
  service: "hsl(279, 49%, 50%)"
};

const defaultColor = "#2E8B96";

class Statistics extends React.Component {
  constructor(props){
    super(props);
    this._filters                     = React.createRef();
    this._transformIssueState         = this._transformIssueState.bind(this);
    this._transformValorizationByType = this._transformValorizationByType.bind(this);
    this._transformValorizationInTime = this._transformValorizationInTime.bind(this);
    this._extraValorizationInTime     = this._extraValorizationInTime.bind(this);
    this._transformValorizationByTag  = this._transformValorizationByTag.bind(this);
    this._extraValorizationByTag      = this._extraValorizationByTag.bind(this);
    this._valorizationInTimeToCsv     = this._valorizationInTimeToCsv.bind(this);
    this._newIssueInTimeToCsv         = this._newIssueInTimeToCsv.bind(this);
    this._valorizationByTagToCsv      = this._valorizationByTagToCsv.bind(this);
    this._valorizationsByTypeToCsv    = this._valorizationsByTypeToCsv.bind(this);
    this._issueStateToCsv             = this._issueStateToCsv.bind(this);
    this._toStdCsv                    = this._toStdCsv.bind(this);
  }
  _transformNewIssueInTime(datas){
    if(!datas.length){
      return [];
    }
    datas = datas.sort((d1, d2) => d1._id.localeCompare(d2._id));
    let month = true;
    
    if(datas[0]._id.substr(0, 7) === datas[datas.length - 1]._id.substr(0, 7)){
      month = false;
    }
    const dic       = {};
    const newDatas  = [];
    if(month){
      datas.forEach(data => {
        const id = data._id.substr(0, 7);
        if(!dic[id]){
          dic[id] = 0;
        }
        dic[id] += data.count;
      });
      let day   = moment(datas[0]._id).startOf("month");
      const end = moment(datas[datas.length - 1]._id).startOf("month");
      while(day.isSameOrBefore(end)){
        newDatas.push({
          id: day.format("MM-YYYY"),
          label: day.format("MM-YYYY"),
          value: dic[day.format("YYYY-MM")] ? dic[day.format("YYYY-MM")] : 0,
          color: defaultColor
        });
        day.add(1, "month");
      }
    }else{
      datas.forEach(data => {
        dic[data._id] = data.count;
      });
      let day   = moment(datas[0]._id).startOf("month");
      const end = moment(datas[datas.length - 1]._id).startOf("month").add(1, "month");
      while(day.isBefore(end)){
        newDatas.push({
          id: day.format("DD-MM-YYYY"),
          label: day.format("DD-MM-YYYY"),
          value: dic[day.format("YYYY-MM-DD")] ? dic[day.format("YYYY-MM-DD")] : 0,
          color: defaultColor
        });
        day.add(1, "day");
      }
    }
    return newDatas;
  }
  _newIssueInTimeToCsv(datas){
    const csv = {};
    if(datas.length){
      datas              = datas.sort((d1, d2) => d1._id.localeCompare(d2._id));
      let day            = moment(datas[0]._id).startOf("month");
      const end          = moment(datas[datas.length - 1]._id).startOf("month").add(1, "month");
      const dayKey       = this.props.i18n.translate("statistics_csv_day");
      const nbrIssuesKey = this.props.i18n.translate("statistics_csv_nbrIssues");
      while(day.isBefore(end)){
        csv[day.format("YYYY-MM-DD")] = {
          [dayKey]: day.format("DD-MM-YYYY"),
          [nbrIssuesKey]: 0
        };
        day.add(1, "day");
      }
      datas.forEach(data => {
        csv[data._id][nbrIssuesKey] = data.count;
      });
    }
    return {
      title: this.props.i18n.translate("statistics_nbr_issues_in_time_csv_title"),
      datas: Object.values(csv)
    };
  }
  _transformIssueState(datas){
    return datas.map(data => {
      return {
        id: this.props.i18n.translate(data._id),
        label: this.props.i18n.translate(data._id),
        value: data.count,
        color: stateColors[data._id]
      }
    });
  }
  _issueStateToCsv(datas){
    return {
      title: this.props.i18n.translate("statistics_issue_state_csv_title"),
      datas: datas.map(data => ({
        [this.props.i18n.translate("statistics_issue_state_state")]: this.props.i18n.translate(data._id),
        [this.props.i18n.translate("statistics_csv_nbrIssues")]: data.count
      }))
    };
  }
  _transformValorizationByType(key, datas){
    return datas.map(data => {
      return {
        id: this.props.i18n.translate(data._id),
        label: this.props.i18n.translate(data._id),
        value: Math.round(data[key] * 100) / 100,
        color: valorizationsColors[data._id]
      }
    });
  }
  _valorizationsByTypeToCsv(key, datas){
    return {
      title: this.props.i18n.translate("statistics_valorization_by_type_csv_title"),
      datas: datas.map(data => ({
        [this.props.i18n.translate("statistics_valorization_by_type")]: this.props.i18n.translate(data._id),
        [this.props.i18n.translate("statistics_valorization_" + key)]: ((Math.round(data[key] * 100) / 100) + "").replace(".", ",")
      }))
    };
  }
  _transformIssueType(datas){
    return [
      { icon: "fa-map-marker", value: datas.publicSpace },
      { icon: "fa-building", value: datas.building },
      { icon: "fa-wrench", value: datas.equipment }
    ];
  }
  _transformIssuesUserOrigin(datas){
    const issuesRequestor = [
      { icon: "fa-address-card-o", value: 0 },
      { icon: "fa-university", value: 0 },
    ];
    datas.forEach(data => {
      let idx = 1;
      if(data._id === "citizen"){
        idx = 0;
      }
      issuesRequestor[idx].value += data.count;
    });
    return issuesRequestor;
  }
  _transformIssuesDeviceOrigin(datas){
    const issuesDevice = [
      { icon: "fa-laptop", value: 0 },
      { icon: "fa-mobile-phone", value: 0 },
    ];
    datas.forEach(data => {
      let idx = 1;
      if(data._id === "web"){
        idx = 0;
      }
      issuesDevice[idx].value += data.count;
    });
    return issuesDevice;
  }
  _horizontalStdTransform(datas){
    datas = datas.slice(0, 8);
    return datas.map(data => {
      let name = data.name ? data.name : data._id;
      let id = name;
      if(id.length > 21){
        id = name.substr(0, 18) + "...";
      }
      return {
        id: id,
        label: name,
        value: data.count,
        color: defaultColor
      }
    });
  }
  _toStdCsv(map, title, datas){
    return {
      title: this.props.i18n.translate(title),
      datas: datas.map(data => Object.keys(map).reduce((object, key) => {
        object[this.props.i18n.translate(map[key])] = data[key];
        return object;
      }, {}))
    };
  }
  _transformValorizationInTime(key, datas){
    if(!datas.length){
      return [];
    }
    datas = datas.sort((d1, d2) => d1._id.localeCompare(d2._id));
    let month = true;
    
    if(datas[0]._id.substr(0, 7) === datas[datas.length - 1]._id.substr(0, 7)){
      month = false;
    }
    const newDatas  = [];
    if(month){
      let day   = moment(datas[0]._id).startOf("month");
      const end = moment(datas[datas.length - 1]._id).startOf("month");
      while(day.isSameOrBefore(end)){
        newDatas[day.format("YYYY-MM")] = {
          id: day.format("MM-YYYY")
        };
        day.add(1, "month");
      }
      datas.forEach(data => {
        if(!data[key]){
          return;
        }
        const keyDate = data._id.substr(0, 7);
        const keyType = this.props.i18n.translate(data.type.toLowerCase());
        if(!newDatas[keyDate][keyType]){
          newDatas[keyDate][keyType] = 0;
          newDatas[keyDate][keyType + "Color"] = valorizationsColors[data.type.toLowerCase()];
        }
        newDatas[keyDate][keyType] += Math.round(data[key] * 100) / 100;        
      });
    }else{
      let day   = moment(datas[0]._id).startOf("month");
      const end = moment(datas[datas.length - 1]._id).startOf("month").add(1, "month");
      while(day.isBefore(end)){
        newDatas[day.format("YYYY-MM-DD")] = {
          id: day.format("DD-MM-YYYY")
        };
        day.add(1, "day");
      }
      datas.forEach(data => {
        newDatas[data._id][this.props.i18n.translate(data.type.toLowerCase())] = Math.round(data[key] * 100) / 100;
        newDatas[data._id][this.props.i18n.translate(data.type.toLowerCase()) + "Color"] = valorizationsColors[data.type.toLowerCase()];
      });
    }
    return Object.values(newDatas);
  }
  _extraValorizationInTime(props, datas){
    props.keys = _.uniq(datas.map(data => this.props.i18n.translate(data.type.toLowerCase())));
  }
  _valorizationInTimeToCsv(key, datas){
    const csv = {};
    if(datas.length){
      datas = datas.sort((d1, d2) => d1._id.localeCompare(d2._id));
      let day   = moment(datas[0]._id).startOf("month");
      const end = moment(datas[datas.length - 1]._id).startOf("month").add(1, "month");
      while(day.isBefore(end)){
        csv[day.format("YYYY-MM-DD")] = {
          [this.props.i18n.translate("statistics_csv_day")]: day.format("DD-MM-YYYY"),
          [this.props.i18n.translate("agent")]: 0,
          [this.props.i18n.translate("team")]: 0,
          [this.props.i18n.translate("patrimony")]: 0,
          [this.props.i18n.translate("supply")]: 0,
          [this.props.i18n.translate("service")]: 0
        };
        day.add(1, "day");
      }
      datas.forEach(data => {
        csv[data._id][this.props.i18n.translate(data.type.toLowerCase())] += data[key];
      });
      const dayCell = this.props.i18n.translate("statistics_csv_day");
      for(let dayRow in csv){
        for(let typeCell in csv[dayRow]){
          if(typeCell !== dayCell){
            csv[dayRow][typeCell] = ((Math.round(csv[dayRow][typeCell] * 100) / 100) + "").replace(".", ",");
          }
        }
      }
    }
    return {
      title: this.props.i18n.translate("statistics_valorization_in_time_csv_title"),
      datas: Object.values(csv)
    };
  }
  _transformValorizationByTag(key, datas){
    const newDatas = {};
    datas.forEach(data => {
      if(!newDatas[data.name]){
        newDatas[data.name] = {
          id: data.name
        };
      }
      newDatas[data.name][this.props.i18n.translate(data.type.toLowerCase())] = Math.round(data[key] * 100) / 100;
      newDatas[data.name][this.props.i18n.translate(data.type.toLowerCase()) + "Color"] = valorizationsColors[data.type.toLowerCase()];
    });
    return Object.values(newDatas);
  }
  _extraValorizationByTag(props, datas){
    props.keys = _.uniq(datas.map(data => this.props.i18n.translate(data.type.toLowerCase())));
  }
  _valorizationByTagToCsv(key, datas){
    const csv = {};
    datas.forEach(data => {
      if(!csv[data.name]){
        csv[data.name] = {
          [this.props.i18n.translate("statistics_csv_category")]: data.name,
          [this.props.i18n.translate("agent")]: 0,
          [this.props.i18n.translate("team")]: 0,
          [this.props.i18n.translate("patrimony")]: 0,
          [this.props.i18n.translate("supply")]: 0,
          [this.props.i18n.translate("service")]: 0
        };
      }
    });
    datas.forEach(data => {
      csv[data.name][this.props.i18n.translate(data.type.toLowerCase())] = ((Math.round(data[key] * 100) / 100) + "").replace(".", ",");
    });
    return {
      title: this.props.i18n.translate("statistics_valorization_by_category_csv_title"),
      datas: Object.values(csv)
    };
  }
  _getFilter = () => {
    return this._filters.current.values.map(v => v.stringify());
  }
  _print(){
    window.print();
  }
  _getPageTitle(i18nKey) {
    return `BS_${moment().format("YYYYMMDD")}_${this.props.i18n.translate(i18nKey)}`;
  }

  render(){
    const defaultFilter = [{ name: "period", value: { type: "last_month", start: null, end: null }, excludes: []}];
    return (
      <Filter.Aggregator default={ defaultFilter } ref={ this._filters }>
        <div className="bs-statistics-container">
          <div className="bs-statistics-container-filters bs-no-print">
            <div className="bs-statistics-container-filters-container">
              <div>
                <ScrollBar>
                  <div className="bs-statistics-container-filters-content">
                    <Filters/>
                  </div>
                </ScrollBar>
              </div>
            </div>
            <div className="bs-statistics-container-filters-bulk">
              <ScrollBar>
                <div className="bs-statistics-container-filters-bulk-viewport">
                  <Filter.Bulk />
                  <FFilter.Manager type="statistics" default={ defaultFilter } title={<T>statistics_myFilters</T>}/>
                </div>
              </ScrollBar>
            </div>
          </div>
            <div className="bs-statistics-container-list">
            <Scrollbar>
              <Filter.Subject>
              {composeQuery => {
                let query = composeQuery();
                let period = null;
                if(query){
                  const periodIdx = query.$and.findIndex(q => q.hasOwnProperty("period"));
                  if(periodIdx !== -1){
                    period = query.$and[periodIdx].period;
                    query.$and.splice(periodIdx, 1);
                  }
                }
                if(!query?.$and.length){
                  query = {};
                }
                query.tenant = this.props.currentTenant.currentId;
                const hash = md5(JSON.stringify({ period, query }));
                return (
                  <Data.Async async={() => this.props.api.service("issues", "statistics").execute(period, query)} hash={ hash }>
                  {datas => {
                    return (
                      <Grid stackable>
                        <Grid.Row>
                          <Grid.Column width={ 16 }>
                            <div className="bs-statistics-header">
                              <div>
                                <Display.If condition={ period }>
                                {() => (<T bind={{ start: moment(period.$gte).format("DD/MM/YYYY"), end: moment(period.$lt).format("DD/MM/YYYY")}}>statistics_from_to</T>)}
                                </Display.If>
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}>
                            <Frame pageTitle={this._getPageTitle("statistics_new_issues")} getFilter={ this._getFilter } datas={ datas.issuesType.length ? datas.issuesType[0] : {} } transform={ datas => Object.values(datas).reduce((total, v) => total + v, 0)} force>
                              <Frame.Title><T>statistics_new_issues</T></Frame.Title>
                              <Frame.Content><Number color="#c0392b" /></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Frame pageTitle={this._getPageTitle("statistics_issues_resolved")} getFilter={ this._getFilter } datas={ datas.issuesResolvedSince.length ? datas.issuesResolvedSince[0].count : 0 } force>
                              <Frame.Title><T>statistics_issues_resolved</T></Frame.Title>
                              <Frame.Content><Number color="#89cc1f" /></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Frame pageTitle={this._getPageTitle("statistics_resolved_time")} getFilter={ this._getFilter } datas={ datas.issuesResolutionDuration.length ? datas.issuesResolutionDuration[0].average : 0 } transform={datas => Math.round(moment.duration(datas, "milliseconds").asDays())} force>
                              <Frame.Title><T>statistics_resolved_time</T></Frame.Title>
                              <Frame.Content><Number color="#9c27b0">{datas => (<><span>{ datas }</span>&nbsp;<span style={{ fontSize: "1rem" }}><T>statistics_resolved_time_days</T></span></>)}</Number></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Frame pageTitle={this._getPageTitle("statistics_unresolved_since_2_month")} getFilter={ this._getFilter } datas={ datas.issuesOpenedSince2Month.length ? datas.issuesOpenedSince2Month[0].count : 0 } force>
                              <Frame.Title><T>statistics_unresolved_since_2_month</T></Frame.Title>
                              <Frame.Content><Number color="#c0392b" /></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={9}>
                            <Frame pageTitle={this._getPageTitle("statistics_new_issues_in_time")} getFilter={ this._getFilter } datas={ datas.newIssuesByDay } transform={ this._transformNewIssueInTime } toCsv={ this._newIssueInTimeToCsv }>
                              <Frame.Title><T>statistics_new_issues_in_time</T></Frame.Title>
                              <Frame.Content height="448px"><NewIssueInTimeBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column width={7}>
                            <Frame pageTitle={this._getPageTitle("statistics_issues_state")} getFilter={ this._getFilter } datas={ datas.issuesStates } transform={ this._transformIssueState } toCsv={ this._issueStateToCsv }>
                              <Frame.Title><T>statistics_issues_state</T></Frame.Title>
                              <Frame.Content height="448px"><Pie/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3}>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_issues_type")} getFilter={ this._getFilter } datas={ datas.issuesType[0] } transform={ this._transformIssueType } force>
                              <Frame.Title><T>statistics_issues_type</T></Frame.Title>
                              <Frame.Content><Percentage /></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_issues_user_origin")} getFilter={ this._getFilter } datas={ datas.issuesRequestor } transform={ this._transformIssuesUserOrigin } force>
                              <Frame.Title><T>statistics_issues_user_origin</T></Frame.Title>
                              <Frame.Content><Percentage /></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_issues_device_origin")} getFilter={ this._getFilter } datas={ datas.issuesDevice } transform={ this._transformIssuesDeviceOrigin } force>
                              <Frame.Title><T>statistics_issues_device_origin</T></Frame.Title>
                              <Frame.Content><Percentage /></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_publicSpace_categories")} getFilter={ this._getFilter } datas={ datas.tagsPublicSpace } transform={ this._horizontalStdTransform } toCsv={ this._toStdCsv.bind(this, { _id: "statistics_csv_category", count: "statistics_csv_nbrIssues" }, "statistics_publicSpace_categories_csv_title") }>
                              <Frame.Title><T>statistics_publicSpace_categories</T></Frame.Title>
                              <Frame.Content height="448px"><HorizontalBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_building_categories")} getFilter={ this._getFilter } datas={ datas.tagsBuilding } transform={ this._horizontalStdTransform } toCsv={ this._toStdCsv.bind(this, { _id: "statistics_csv_category", count: "statistics_csv_nbrIssues" }, "statistics_building_categories_csv_title") }>
                              <Frame.Title><T>statistics_building_categories</T></Frame.Title>
                              <Frame.Content height="448px"><HorizontalBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_equipment_categories")} getFilter={ this._getFilter } datas={ datas.tagsPatrimony } transform={ this._horizontalStdTransform } toCsv={ this._toStdCsv.bind(this, { _id: "statistics_csv_category", count: "statistics_csv_nbrIssues" }, "statistics_equipment_categories_csv_title") }>
                              <Frame.Title><T>statistics_equipment_categories</T></Frame.Title>
                              <Frame.Content height="448px"><HorizontalBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_most_actif_creators")} getFilter={ this._getFilter } datas={ datas.creators } transform={ this._horizontalStdTransform } toCsv={ this._toStdCsv.bind(this, { name: "statistics_csv_creators", count: "statistics_csv_nbrIssues" }, "statistics_most_actif_creators_csv_title") }>
                              <Frame.Title><T>statistics_most_actif_creators</T></Frame.Title>
                              <Frame.Content height="448px"><HorizontalBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_most_actif_requestors")} getFilter={ this._getFilter } datas={ datas.requestors } transform={ this._horizontalStdTransform } toCsv={ this._toStdCsv.bind(this, { name: "statistics_csv_requestors", count: "statistics_csv_nbrIssues" }, "statistics_most_actif_requestors_csv_title") }>
                              <Frame.Title><T>statistics_most_actif_requestors</T></Frame.Title>
                              <Frame.Content height="448px"><HorizontalBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_most_actif_building")} getFilter={ this._getFilter } datas={ datas.buildings } transform={ this._horizontalStdTransform } toCsv={ this._toStdCsv.bind(this, { name: "statistics_csv_building", count: "statistics_csv_nbrIssues" }, "statistics_most_actif_building_csv_title") }>
                              <Frame.Title><T>statistics_most_actif_building</T></Frame.Title>
                              <Frame.Content height="448px"><HorizontalBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column>
                            <Frame pageTitle={this._getPageTitle("statistics_most_actif_equipment")} getFilter={ this._getFilter } datas={ datas.patrimonies } transform={ this._horizontalStdTransform } toCsv={ this._toStdCsv.bind(this, { name: "statistics_csv_equipment", count: "statistics_csv_nbrIssues" }, "statistics_most_actif_equipment_csv_title") }>
                              <Frame.Title><T>statistics_most_actif_equipment</T></Frame.Title>
                              <Frame.Content height="448px"><HorizontalBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={8}>
                            <Frame pageTitle={this._getPageTitle("statistics_valorization_in_time_cost")} getFilter={ this._getFilter } datas={ datas.valorizationsInTime } transform={ this._transformValorizationInTime.bind(this, "cost") } extra={ this._extraValorizationInTime } toCsv={ this._valorizationInTimeToCsv.bind(this, "cost") }>
                              <Frame.Title><T>statistics_valorization_in_time_cost</T></Frame.Title>
                              <Frame.Content height="448px"><ValorizationBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Frame pageTitle={this._getPageTitle("statistics_valorization_in_time_hour")} getFilter={ this._getFilter } datas={ datas.valorizationsHoursInTime } transform={ this._transformValorizationInTime.bind(this, "quantity")  } extra={ this._extraValorizationInTime } toCsv={ this._valorizationInTimeToCsv.bind(this, "quantity") }>
                              <Frame.Title><T>statistics_valorization_in_time_hour</T></Frame.Title>
                              <Frame.Content height="448px"><ValorizationBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={8}>
                            <Frame pageTitle={this._getPageTitle("statistics_valorization_by_type_cost")} getFilter={ this._getFilter } datas={ datas.valorizationsByType } transform={ this._transformValorizationByType.bind(this, "cost") } toCsv={ this._valorizationsByTypeToCsv.bind(this, "cost") }>
                              <Frame.Title><T>statistics_valorization_by_type_cost</T></Frame.Title>
                              <Frame.Content height="448px"><Pie/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Frame pageTitle={this._getPageTitle("statistics_valorization_by_type_hour")} getFilter={ this._getFilter } datas={ datas.valorizationsHoursByType } transform={ this._transformValorizationByType.bind(this, "quantity") } toCsv={ this._valorizationsByTypeToCsv.bind(this, "quantity") }>
                              <Frame.Title><T>statistics_valorization_by_type_hour</T></Frame.Title>
                              <Frame.Content height="448px"><Pie/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={8}>
                            <Frame pageTitle={this._getPageTitle("statistics_valorization_by_category_cost")} getFilter={ this._getFilter } datas={ datas.valorizationsByTag } transform={ this._transformValorizationByTag.bind(this, "cost") } extra={ this._extraValorizationByTag } toCsv={ this._valorizationByTagToCsv.bind(this, "cost") }>
                              <Frame.Title><T>statistics_valorization_by_category_cost</T></Frame.Title>
                              <Frame.Content height="448px"><ValorizationBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Frame pageTitle={this._getPageTitle("statistics_valorization_by_category_hour")} getFilter={ this._getFilter } datas={ datas.valorizationsHoursByTag } transform={ this._transformValorizationByTag.bind(this, "quantity") } extra={ this._extraValorizationByTag } toCsv={ this._valorizationByTagToCsv.bind(this, "quantity")}>
                              <Frame.Title><T>statistics_valorization_by_category_hour</T></Frame.Title>
                              <Frame.Content height="448px"><ValorizationBar/></Frame.Content>
                            </Frame>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )
                  }}
                  </Data.Async>
                )
              }}
              </Filter.Subject>
            </Scrollbar>
          </div>
        </div>
      </Filter.Aggregator>
    );
  }
}

export default Application.Service.forward(["api", "i18n", "currentTenant"], Statistics);