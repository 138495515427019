import React          from "react";
import _              from "lodash";
import md5            from "md5";
import Data           from "@uBehaviour/data";
import Button         from '@cComponents/button';
import Category       from "@entities/category";
import Modal          from '@cComponents/modal';
import T              from "@cBehaviour/i18n";
import Filter         from "@cComponents/filter";
import FilteredList   from "@cFeatures/filter/list";
import Input          from "@cComponents/input";
import SelectManager  from "./selectManager";
import Display        from "@uComponents/displayIf";
import Acl            from "@uBehaviour/acl";
import FormMultiStructure from "./formMultiStructure";
import { types }    from "@entities/category/filters";
import { combinate } from "@uLib/query";
import CurrentTenantService from "@uServices/currentTenant";
import AclService from "@uServices/acl";
import I18nService from "@uServices/i18n";
import useService from "@uBehaviour/hooks/useService";
import ApiService from "@uServices/api";
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import CategoryForm from './form';
import { Link } from "@cFeatures/router";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";

import "./list.css";

const NbrCategory = (props) => (
  <Filter.Setter multiple name="type">
  {(value, add, drop, clear) => {
    const selected = value.indexOf(props.value) !== -1;
    return (
      <Data.Async async={() => props.api.service("categories", "count").execute(props.query)} hash={md5(JSON.stringify(props.query))}>
      {(nbr) => (
        <span className={ "bs-nbrCategory" + (selected ? " bs-nbrCategory-selected" : "")} onClick={ () => !selected ? add(props.value) : drop(props.value)}><span className={`fa fa-${props.fa}`} /><span>{ nbr }</span></span>
      )}
      </Data.Async>
    )
  }}
  </Filter.Setter>
);

const query = (currentTenant: CurrentTenantService, acl: AclService) => {
  const allowToReadBuilding = acl.connectedUserIsAllow("categories", "readBuilding");
  const allowToReadEquipment = acl.connectedUserIsAllow("categories", "readEquipment");
  
  return combinate("$and",
    currentTenant.currentId ? { tenant: currentTenant.currentId }: null,
    !allowToReadBuilding ? { type: { $ne: "building" }} : null,
    !allowToReadEquipment ? { type: { $ne: "equipment" }} : null,
  );
}
const textSearchBuildQuery = (value: string, i18n: I18nService) => {
  return { [i18n.queryProperty("label")]: { '$regex': escapeRegExp(value), '$options': 'i' } };
}

const defaultQuery = () => {
  return [{ name: "disabled", value: false }];
}

const LinkedCategoryItem = withLink(Category.Item);
const ModalizedCategoryForm = withModalLinked(CategoryForm);

const CategoryList = () => {
  const currentTenant = useService<CurrentTenantService>('currentTenant');
  const i18n = useService<I18nService>('i18n');
  const api = useService<ApiService>('api');
  const acl = useService<AclService>('acl');

  const [state, setState] = React.useState({ 
    displaySelectManager: false,
    displayBulkForm: false
  });
  return (
    <>
      <FilteredList default={defaultQuery()}>
        <FilteredList.Filter>
          <Category.Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Left>
              <Filter.Subject>
                {composeQuery => (
                  <span className="bs-nbrCategory-container">
                    <NbrCategory api={ api } fa="road" query={ composeQuery({ type: "publicSpace"}) } value={types[0]}/>
                    <NbrCategory api={ api } fa="building-o" query={ composeQuery({ type: "building"}) } value={types[1]}/>
                    <NbrCategory api={ api } fa="truck" query={ composeQuery({ type: "equipment"}) } value={types[2]}/>
                  </span>
                )}
              </Filter.Subject>
            </ListHeaderLayout.Left>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={value => textSearchBuildQuery(value, i18n)} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
              {(value, set, clear) => (
                <Searchbar>
                  <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                    <T>freesearch</T>
                  </Input.Text>
                </Searchbar>
              )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right>
              <Link to="/new">
                <Button.Stylized.Text><T>add</T></Button.Stylized.Text>
              </Link>
              <Acl.If resource="application" action="administrate">
                <Button.Text onClick={() => setState({ displaySelectManager: true }) }><T>category_list_display_selectManager</T></Button.Text>
              </Acl.If>
              <Display.If condition={ acl.connectedUserIsAllow("application", "administrate") || currentTenant.configurableTenants.length > 1 }>
                <Button.Text onClick={() => setState({ displayBulkForm: true }) }><T>category_list_display_bulkForm</T></Button.Text>
              </Display.If>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
          {composeQuery => (
            <Data.List model={"Category"} query={composeQuery(query(currentTenant, acl))} load={{ tenant:true, "redirectRule.manager": true, "redirectRule.category":{tenant:true}, "redirectRule.categories":{tenant:true}, "redirectRule.defaultTransfer":true }} sort={{ [i18n.queryProperty('label')]: 1}}>
              <LinkedCategoryItem />
            </Data.List>
          )}
        </FilteredList.List>
      </FilteredList>
      <Display.If condition={ state.displaySelectManager }>
      {() => (
        <Modal.Show close={() => setState({ displaySelectManager : false })} style={{ height: "90vh"}}>
          <SelectManager />
        </Modal.Show>
      )}
      </Display.If>
      <Display.If condition={ state.displayBulkForm }>
      {() => (
        <Modal.Show close={() => setState({ displayBulkForm : false })} style={{ height: "90vh", width: "80vw"}}>
          <FormMultiStructure />
        </Modal.Show>
      )}
      </Display.If>
      <ModalizedCategoryForm style={{ width: "80vw", height: "90vh" }} />
    </>
  );
}

export default CategoryList;
