// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-date{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}
.bs-input-date.bs-not-fluid{
  width: min-content;
}
.bs-input-date>div{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
}
.bs-input-date>div>div:nth-child(1){
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  padding-left: 19px;
  flex-grow: 1;
}


.bs-input-date>div>div:nth-child(2){
  z-index: 1;
  background-color: white;
  position: absolute;
  top: 100%;
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 2px 2px 3px rgba(0, 0, 0, 0.2)
}

.bs-input-date input,
.bs-input-hour input {
  flex-grow: 1;
  border-radius: 0px !important;
  height: auto !important;
  border: none !important;
  background: none !important;
  margin: 0 !important;
  padding: 0px !important;
  box-shadow: none !important;
  color: #2c3e50 !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  outline: none;
  max-width: 110px;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/date.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,YAAY;AACd;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,wBAAwB;EACxB,kBAAkB;EAClB,YAAY;AACd;;;AAGA;EACE,UAAU;EACV,uBAAuB;EACvB,kBAAkB;EAClB,SAAS;EACT,kBAAkB;EAClB,mBAAmB;EACnB;AACF;;AAEA;;EAEE,YAAY;EACZ,6BAA6B;EAC7B,uBAAuB;EACvB,uBAAuB;EACvB,2BAA2B;EAC3B,oBAAoB;EACpB,uBAAuB;EACvB,2BAA2B;EAC3B,yBAAyB;EACzB,2BAA2B;EAC3B,0BAA0B;EAC1B,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".bs-input-date{\n  width: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: row;\n}\n.bs-input-date.bs-not-fluid{\n  width: min-content;\n}\n.bs-input-date>div{\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 1;\n  flex-grow: 1;\n}\n.bs-input-date>div>div:nth-child(1){\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n  align-items: center;\n  border-bottom: 1px solid;\n  padding-left: 19px;\n  flex-grow: 1;\n}\n\n\n.bs-input-date>div>div:nth-child(2){\n  z-index: 1;\n  background-color: white;\n  position: absolute;\n  top: 100%;\n  width: fit-content;\n  height: fit-content;\n  box-shadow: 0px 2px 2px 3px rgba(0, 0, 0, 0.2)\n}\n\n.bs-input-date input,\n.bs-input-hour input {\n  flex-grow: 1;\n  border-radius: 0px !important;\n  height: auto !important;\n  border: none !important;\n  background: none !important;\n  margin: 0 !important;\n  padding: 0px !important;\n  box-shadow: none !important;\n  color: #2c3e50 !important;\n  font-weight: 500 !important;\n  font-size: 1rem !important;\n  outline: none;\n  max-width: 110px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
