import { Helper } from "@uLib/application";

export default class UserHelper extends Helper {
  constructor(){
    super("users", ["api", "session", "message", "configuration", "acl", "i18n"]);
  }
  get geographicalAreas() {
    const userSession = this.application.getService("session").user;
    return userSession && userSession.geographicalAreas && userSession.geographicalAreas.length 
      ? userSession.geographicalAreas
      : null;
  }

  async sendInvitation(user){
    const api     = this.application.getService("api");
    const message = this.application.getService("message");
    const i18n    = this.application.getService("i18n");
    try {
      await api.service("users", "invite").execute(user._id.toString());
      message.send("info", i18n.translate("user_helper_invitation_send", { fullname: user.fullname }));
    } catch(err) {
      if(err.code === 1510) {
        message.send("error", i18n.translate("user_helper_invitation_no_mail_error"));
      }
    }
  }

  sendCitizenInvitation(user){
    const api     = this.application.getService("api");
    const message = this.application.getService("message");
    api.service("users", "inviteCitizen").execute(user._id).then(() => {
      message.send("info", `Message d'invitation envoyé à ${user.fullname}`);
    });
  }

  resetPin(userId){
    const api     = this.application.getService("api");
    const message = this.application.getService("message");
    api.service("users", "resetPin").execute(userId).then(user => {
      message.send("info", `Le code pin de ${user.fullname} a été réinitialisé`);
    });
  }
  isAllowToChangePasswordOn(user){
    const configuration = this.application.getService("configuration");
    return !!user.authentificationProviders?.some(ap => ap.discriminator === "username")
      && (
            !user.email 
        || configuration.get("env") === "development" 
        || (user.discriminator === "collaborator" && user.roles.length === 1 && user.roles[0] === "issueProvider")
      );
  }
  getUserManagedTenant(user){
    const session = this.application.getService("session");
    const acl     = this.application.getService("acl");
    const isManager = acl.connectedUserIsAllow("users", "manage");
    if(["collaborator", "tablet", "technical"].includes(user.discriminator) || !isManager){
      return [];
    }
    return user.tenants.filter(t => t.tenant && acl.isAllow("users", "manage", session.user, t.tenant._id ? t.tenant._id : t.tenant));
  }

  getManagedTenant(){
    const session = this.application.getService("session");
    const acl     = this.application.getService("acl");
    return session.user.discriminator === "collaborator"
      ? []
      : session.user.tenants.filter(t => acl.isAllow("users", "manage", session.user, t.tenant));
  }

  updatePassword(userId, password){
    const session = this.application.getService("session");
    const api     = this.application.getService("api");
    if (session.user._id === userId) {
      return session.updateMyPassword(password);
    } else {
      return api.service("users", "resetPassword").execute(userId, password);
    }
  }

  getAgents(page, limit){
    const currentTenant = this.application.getService("currentTenant");
    const api           = this.application.getService("api");
    return api.service("users", "get").execute({ tenants: { $elemMatch: { tenant: currentTenant.currentId, roles: { $in: ["agent"]}}} }, { fullname: -1 }, page, limit);
  }
}